<template>
  <!-- 通用底部弹出层 -->
  <van-popup
    v-model="isShow"
    round
    get-container="body"
    position="bottom"
    class="normal-bottom-popup"
    :transition="transition"
    :close-on-click-overlay="closeOnClickOverlay"
    :close-on-popstate="closeOnPopstate"
  >
    <div class="normal-popup-wrapper">
      <!-- 完全自定义header -->
      <slot name="custom-header">
        <div class="header-wrapper" :class="{'scrolling-style': isScrolling}">
          <!-- 具备滑动显示阴影的自定义header -->
          <slot name="header">
            <div class="normal-header-container">
              <div v-if="showLeftIcon" class="header-left-container">
                <slot name="left-icon">
                  <div class="header-icon-btn-container" @click="leftIconHandler">
                    <i class="iconfont icon-close-new" />
                  </div>
                </slot>
              </div>
              <div class="title-container">
                <slot name="header-title">
                  <h1 class="title">
                    {{ popupTitle }}
                  </h1>
                </slot>
              </div>
              <div v-if="showRightIcon" class="header-right-container">
                <slot name="right-icon">
                  <div :class="`header-icon-btn-container ${rightIconClose?'':'success-icon-container'} `" @click="rightIconHandler">
                    <template v-if="rightIconClose">
                      <i class="iconfont icon-close-new" />
                    </template>
                    <i v-else class="iconfont icon-success" />
                  </div>
                </slot>
              </div>
            </div>
            <slot name="header-bottom" />
          </slot>
        </div>
      </slot>

      <!-- 完全自定义body -->
      <slot name="custom-body">
        <div id="normal-bottom-popup-body-wrapper" class="body-wrapper" @scroll="watchScroll">
          <!-- 具备滑动显示阴影的自定义body -->
          <slot name="body" />
        </div>
      </slot>
    </div>
  </van-popup>
</template>

<script>
// 节流函数
const throttle = require('lodash/throttle');

export default {
  props: {
    // 弹出层是否显示
    show: {
      default: false,
      type: Boolean,
    },
    // 弹出层标题
    popupTitle: {
      default: '',
      type: String,
    },
    // 是否显示遮罩层
    overlay: {
      default: true,
      type: Boolean,
    },
    // 是否在点击遮罩层后关闭
    closeOnClickOverlay: {
      default: false,
      type: Boolean,
    },
    // vant弹出层组件自带transition属性
    transition: {
      default: '',
      type: String,
    },
    // 是否在页面回退时自动关闭
    closeOnPopstate: {
      default: false,
      type: Boolean,
    },
    // 是否显示左按钮
    showLeftIcon: {
      default: true,
      type: Boolean,
    },
    // 左侧按钮icon
    leftIconClass: {
      default: 'iconfont icon-close',
      type: String,
    },
    // 是否显示右按钮
    showRightIcon: {
      default: true,
      type: Boolean,
    },
    // 右侧按钮icon
    rightIconClass: {
      default: 'iconfont icon-success',
      type: String,
    },
    // 右侧是关闭按钮
    rightIconClose: {
      default: false,
      type: Boolean,
    },
  },
  data() {
    return {
      isScrolling: false,
      pannelBodyScrollTop: 0,
    }
  },
  computed: {
    isShow: {
      get() {
        return this.show;
      },
      set(newValue) {
        this.$emit('update:show', newValue)
        return newValue;
      },

    },
  },
  watch: {
    pannelBodyScrollTop(newVal) {
      setTimeout(() => {
        const ele = document.getElementById('normal-bottom-popup-body-wrapper')
        if (newVal !== ele.scrollTop) {
          this.isScrolling = true;
        } else {
          this.isScrolling = false;
        }
      }, 100)
    },
  },
  methods: {
    watchScroll: throttle(function watchScrollTop() {
      const ele = document.getElementById('normal-bottom-popup-body-wrapper');
      this.pannelBodyScrollTop = ele.scrollTop;
    }),
    rightIconHandler() {
      this.$emit('right-icon-handler');
    },
    leftIconHandler() {
      this.$emit('left-icon-handler');
    },
  },
}
</script>

<style lang="scss" scoped>
.normal-popup-wrapper {
  background: #f4f6fa;

  .header-wrapper {
    padding: 0 px2vw(32);
    background: #fff;

    &.scrolling-style {
      position: relative;

      &::after {
        content: '';
        position: absolute;
        height: px2vw(60);
        left: 0;
        right: 0;
        background: linear-gradient(180deg, rgba(222, 226, 231, 0.6) 0%, rgba(222, 226, 231, 0) 100%);
        z-index: 1;
      }
    }

    .title,
    h1 {
      text-align: center;
      font-size: px2vw(32);
      font-weight: 600;
      line-height: px2vw(42);
      color: var(--txt-color-lv1);
    }

    .normal-header-container {
      position: relative;
      padding: px2vw(28) 0;
      display: flex;
      justify-content: center;
      align-items: center;
      height: px2vw(120);
    }

    .normal-header-container .header-left-container,
    .normal-header-container .header-right-container {
      position: absolute;
      top: px2vw(28);

      .header-icon-btn-container {
        display: flex;
        justify-content: center;
        align-items: center;
        width: px2vw(64);
        height: px2vw(64);
        text-align: center;
        border-radius: 50%;
        color: var(--txt-color-lv2);
        background: #f4f6fa;

        &.success-icon-container {
          color: var(--txt-color-link);
          background: var(--txt-color-link3);
        }

        .iconfont {
          font-size: px2vw(40);
        }
      }
    }

    .normal-header-container .header-left-container {
      left: 0;
    }

    .normal-header-container .header-right-container {
      right: 0;
    }
  }

  .body-wrapper {
    position: relative;
    padding: px2vw(20) px2vw(32);
    min-height: px2vw(924);
    max-height: px2vw(992);
    overflow: scroll;
    background: #f4f6fa;
  }
}
</style>
