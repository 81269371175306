<template>
  <div>
    <BottomPopup :show="show" class="timezone-change-popup">
      <template slot="custom-header">
        <div class="dialog-header">
          <div class="header-container">
            <div class="title">
              {{ langContext.title }}
            </div>
            <div class="describe">
              {{ langContext.describe }}<span class="current-timezone">{{ timezone }}</span>
            </div>
          </div>
          <div class="operate-dialog-icon close-icon" @click="handleClose">
            <i class="iconfont icon-close-new" />
          </div>
        </div>
      </template>
      <template slot="custom-body">
        <div class="timezone-wrapper">
          <div class="timezone-filter">
            <div class="iconfont icon-search" />
            <input v-model="filterValue" class="timezone-input" :placeholder="langContext.searchInputPlaceholder">
            <div v-if="filterValue" class="icon-close-wrapper" @click="clearFilterValue">
              <i class="iconfont icon-close-new" />
            </div>
          </div>
          <div class="timezone-result-wrapper">
            <template v-if="loading">
              <Loading :show="true" />
            </template>
            <template v-else>
              <div v-if="showEmpty" class="empty-wrapper">
                <div class="notice">
                  {{ langContext.noResultNotice }}
                </div>
              </div>
              <div v-else id="timezone-popup-result" class="timezone-result">
                <div id="timezone-popup-result-wrapper" class="result-wrapper">
                  <template v-for="(item, index) in timezoneList">
                    <div v-if="filterTimezone(item)" :key="index" class="timezone-item" :class="{'active': item === timezone}" @click="handleChangeTimezone(item)">
                      <div class="timezone-name" v-html="handleHighlight(item)" />
                      <div v-if="item === timezone" class="icon-checked">
                        <i class="iconfont icon-success" />
                      </div>
                    </div>
                  </template>
                </div>
              </div>
            </template>
          </div>
        </div>
      </template>
    </BottomPopup>
  </div>
</template>

<script>
/**
 * 时区切换窗口web组件
 */
import mixin from './mixin'
import Loading from 'components/common/Loading'
import BottomPopup from 'components/common/BottomPopup'

export default {
  name: 'TimezoneChangePopup',
  components: {
    Loading,
    BottomPopup,
  },
  mixins: [mixin],
  methods: {
    /**
     * 点击时区切换
     * @param {String} timezone 时区
     */
    handleChangeTimezone(timezone) {
      if (timezone === this.timezone) return
      this.setTimezone(timezone)
      this.handleClose()
      this.trackReport()
      this.$Toast(this.langContext.refreshNotice)
      this.$emit('confirm')
      setTimeout(() => {
        window.location.reload()
      }, 2000)
    },
  },
}
</script>

<style lang="scss" scoped>
.dialog-header {
  position: relative;

  .operate-dialog-icon {
    position: absolute;
    top: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    color: #a1abb8;
    background: #f4f6fa;
    cursor: pointer;

    &.close-icon {
      right: 20px;
    }
  }

  .header-container {
    padding: 20px 60px 20px 16px;

    .title {
      margin-bottom: 20px;
      color: var(--txt-color-lv1);
      font-size: 18px;
      font-weight: 600;
    }

    .describe {
      color: var(--txt-color-lv2);
      font-size: 12px;
      line-height: 28px;
      line-height: 14px;
    }

    .current-timezone {
      color: #3370ff;
    }
  }
}

.timezone-wrapper {
  padding: 0 16px 20px 16px;
}

.timezone-filter {
  position: relative;
  margin-bottom: 20px;

  .icon-close-wrapper {
    position: absolute;
    top: 10px;
    right: 10px;
    width: 20px;
    height: 20px;
    background: #fff;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    .icon-close-new {
      font-size: 14px;
      color: var(--txt-color-lv1);
    }
  }

  .icon-search {
    position: absolute;
    left: 12px;
    top: 11px;
    font-size: 16px;
    color: var(--txt-color-lv2);
  }
}

.timezone-input {
  display: block;
  width: 100%;
  height: 40px;
  background: #f8f9fa;
  font-size: 14px;
  padding: 0 36px 0 36px;
  border-radius: 20px;
  border: 1px solid #f8f9fa;
  color: var(--txt-color-lv1);

  &:focus {
    border: 1px solid var(--txt-color-link);
  }

  &:focus-visible {
    outline: 0;
  }

  &::placeholder {
    color: #a2aabb;
  }
}

.timezone-result-wrapper {
  height: 343px;
}

.empty-wrapper {
  overflow: hidden;

  &::before {
    content: " ";
    display: block;
    width: 160px;
    height: 160px;
    margin: 80px auto 20px auto;
    background: url('./assets/no-search-result.png') no-repeat;
    background-size: 100%;
  }

  .notice {
    font-size: 12px;
    color: var(--txt-color-lv2);
    text-align: center;
  }
}

.timezone-result {
  max-height: 343px;
  background: #f8f9fa;
  border-radius: 10px;
  padding: 0 16px;
  overflow: auto;

  .timezone-name {
    width: 285px;
    font-size: 16px;
    color: var(--txt-color-lv1);
    font-weight: bold;

    span {
      color: var(--txt-color-link);
    }
  }

  .timezone-item {
    display: flex;
    padding: 20px 0;
    justify-content: space-between;
    align-items: center;
    border-bottom: 2px solid #fff;
    cursor: pointer;

    &:last-child {
      border-bottom: none;
    }

    &.active,
    &:hover {
      .timezone-name {
        color: var(--txt-color-link);
      }
    }
  }

  .icon-checked {
    color: var(--txt-color-link);
    font-size: 20px;
  }
}

.timezone-change-popup {
  /deep/.normal-popup-wrapper {
    background: #fff;
  }
}
</style>
