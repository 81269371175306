/* eslint-disable max-len */
// 绑定弹框配置
export default {
  phone: {
    title: 'Associate your phone number',
    subTitle: "Please provide an accurate phone number to receive feedback from teachers regarding your child's classroom performance.",
  },
  email: {
    title: 'Associate your email address',
    subTitle: 'Please provide an accurate email address to receive free learning resources and learning consultation',
  },
  submitBtn: 'Confirm',
  tip: 'If you have any questions, please contact us at:',
};
