<template>
  <div>
    <div v-if="isApp === true">
      <van-nav-bar
        title="Error"
        left-arrow
        @click-left="handleBack"
      />
      <div class="container container-error">
        <div class="error-img">
          <img v-lazy-load data-src="~assets/images/error/bg-error.png">
        </div>
        <div class="error-notice">
          <template v-if="isError">
            <p>Sorry, a server error occurred.</p>
            <p>Please try again later.</p>
          </template>
          <template v-else-if="statusCode === 404">
            <p>Sorry, page not found.</p>
          </template>
          <template v-else>
            <p>Sorry, a server error occurred.</p>
            <p>Please try again later.</p>
          </template>
        </div>
      </div>
    </div>
    <div v-if="isApp === false">
      <Header :show-sign-in="false" />
      <div class="container container-error">
        <Exception :tip-text="exceptionTipText" placeholder-class-name="system-error-img" :show-retry-btn="showRetry" :show-back-btn="showBack" @retry="reload" />
      </div>
      <Footer />
    </div>
  </div>
</template>

<script>
import Header from '@/components/global/FeatureComponents/Header/touch.vue';
import Footer from '@/components/global/FeatureComponents/Footer/touch.vue'
import Exception from 'components/common/Exception/index.vue';

export default {
  components: {
    Header,
    Footer,
    Exception,
  },
  props: {
    error: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      isApp: '',
      showRetry: false,
      showBack: true,
    }
  },
  computed: {
    statusCode() {
      return (this.error && this.error.statusCode) || 500;
    },
    isError() {
      return this.$route.path === '/error';
    },
    exceptionTipText() {
      if (this.statusCode && !this.isError) {
        return `${this.statusCode} - System error. Please try again later.`
      }
      return 'Sorry, a server error occurred.'
    },
  },
  mounted() {
    this.isApp = this.$store.state.appDevice.toLowerCase() === 'ios' || this.$store.state.appDevice.toLowerCase() === 'android';
    this.$Dialog.close();
  },
  methods: {
    handleBack() {
      this.$router.push('/app')
    },
    reload() {
      if (this.isError) {
        window.location.href = document.referrer === '' ? window.location.origin : document.referrer;
        return;
      }
      // reload
      window.location.reload();
    },
  },
  head() {
    return {
      htmlAttrs: {
        id: `style__${this.$store.state.betterme}`,
      },
      link: [{
        hid: 'icon',
        rel: 'icon',
        type: 'image/x-icon',
        href: this.$store.state.betterme == 'bettermeedu' ? 'https://download-pa-s3.thethinkacademy.com/static-pa/project/game/assets/favicon.ico' : '/favicon.ico',
      }],
    }
  },
}
</script>

<style scoped lang="scss">
.container-error {
  padding: px2vw(150) px2vw(0);
}

.error-img,
.error-img img {
  width: px2vw(300);
}

.error-img {
  margin: 0 auto;
}

.error-notice {
  text-align: center;
  color: #999;
  font-size: px2vw(30);

  p {
    margin-bottom: px2vw(20);
  }
}
</style>
