<template>
  <div class="nav-header-item">
    <div class="navigation-item" @click="clickNav(item.name)">
      <a v-if="item.target && item.target=='_blank'" :href="item.url" :target="item.target">{{ item.name }}</a>
      <a
        v-else-if="item.url"
        :href="item.url"
        :target="item.target"
      >
        <i class="iconfont icon-arrow-right-outline" />
        <span>
          {{ item.name }}
        </span>
      </a>

      <p v-else class="item-name">
        {{ item.name }}
      </p>
      <i v-if="item.url" class="iconfont icon-arrow-right-outline" />
    </div>
    <template v-if="item.children && item.children.length">
      <div class="nav-children-list">
        <TreeNode v-for="(itemChild, index) in item.children" :key="index" :item="itemChild" @click.native="clickChildItem(itemChild.name)" />
      </div>
    </template>
  </div>
</template>
<script>

export default {
  name: 'TreeNode',
  props: {
    item: {
      type: Object,
      default: () => ({}),
    },
  },
  methods: {
    clickChildItem() {
      this.$emit('closeNavigation')
    },
    clickNav(name) {
      this.$emit('closeNavigation')
      this.$emit('click-nav-item-sa', name)
    },
  },
}
</script>
<style lang="scss">
    .navigation-item {
      display: flex;
      position: relative;
      height: 48px;
      padding-left: 20px;
      padding-right: 20px;
      align-items: center;
      background: #fff;
      font-size: 16px;
      border-radius: 10px;

      a {
        color: rgba(69, 85, 113, 1);
        display: flex;
        flex: 1;
        line-height: 44px;
      }

      i {
        position: absolute;
        right: 15px;
        color: #bdbdbe;
        font-size: 8px;
      }

      .item-name {
        color: var(--txt-color-lv3);
      }
    }

    .hasChildrenCls {
      display: flex;
      flex-direction: column;
      width: 100%;

      .item-name {
        padding-left: 15px;
        margin-bottom: 5px;
        margin-top: 20px;
        font-size: 12px;
        color: #bdbdbe;
      }

      .navigation-item {
        padding-left: 15px;
        padding-right: 15px;
      }
    }

    .nav-children-list {
      width: 100%;

      .nav-header-item {
        margin-top: 1px;

        .navigation-item {
          font-size: 14px;
          position: relative;
          padding-left: 36px;

          &::before {
            position: absolute;
            left: 20px;
            content: '';
            height: 2px;
            width: 6px;
            background: #455571;
            border-radius: 1px;
          }
        }
      }
    }
</style>
