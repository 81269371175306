<template>
  <div v-if="isShow" class="cookies-accecpt">
    <div class="cookies-accecpt-img" />
    <div class="cookies-accecpt-title">
      {{ title }}
    </div>
    <div class="cookies-accecpt-description" v-html="description" />
    <div class="cookies-accecpt-button">
      <div class="button button-normal button-bg-transparent" @click="handleReject('Reject')">
        {{ $t('featureComponents.page.cookiesAccept.reject') }}
      </div>
      <div class="button button-normal button-primary-white" @click="handleAccept('Accept')">
        {{ $t('featureComponents.page.cookiesAccept.accept') }}
      </div>
    </div>
  </div>
</template>

<script>
// import commonPagesConfig from 'config/pages/common.js';

export default {
  data() {
    return {
      isShow: false,
      title: this.$t('featureComponents.page.cookiesAccept.title'),
      description: this.$t('featureComponents.page.cookiesAccept.description'),
    }
  },
  mounted() {
    const cookiesAccept = window.localStorage.getItem('cookiesAccept', 1);
    if (!cookiesAccept) {
      this.isShow = true;
    }
  },
  methods: {
    handleAccept(btnName) {
      this.isShow = false;
      this.cookiesIsAcceptSa(btnName)
      window.localStorage.setItem('cookiesAccept', 1);
    },
    handleReject(btnName) {
      this.isShow = false;
      this.cookiesIsAcceptSa(btnName)
      window.localStorage.setItem('cookiesAccept', 0);
    },
    cookiesIsAcceptSa(btnName) {
      this.Sensors.track('bottomsheets_click', {
        button_content: btnName,
      })
    },
  },
};
</script>

<style lang="scss">
.cookies-accecpt {
  position: fixed;
  bottom: 0;
  z-index: 1000;
  padding: px2vw(28) px2vw(32) 0 px2vw(32);
  box-shadow: 0 px2vw(20) px2vw(60) 0 rgba(0, 0, 0, 0.05);
  width: 100%;
  // height: px2vw(500);
  background: var(--txt-color-link);
  border-radius: px2vw(32) px2vw(32) 0 0;

  .cookies-accecpt-img {
    box-shadow: 0 px2vw(20) px2vw(60) 0 rgba(0, 0, 0, 0.05);
    position: absolute;
    border-radius: 50%;
    right: px2vw(30);
    top: px2vw(-50);
    height: px2vw(180);
    width: px2vw(180);
    background-image: url('~assets/images/common/cookies-policy-img.png');
    background-size: 100%;
  }

  .cookies-accecpt-title {
    // margin-bottom: px2vw(32);
    height: px2vw(58);
    font-size: px2vw(40);
    font-weight: 600;
    color: #fff;
    line-height: px2vw(58);
  }

  .cookies-accecpt-description {
    width: 83%;
    margin-bottom: px2vw(32);

    >p {
      font-size: px2vw(24);
      font-weight: 500;
      color: #fff;

      >a {
        color: #fff;
        text-decoration: underline;
      }
    }
  }

  .cookies-accecpt-button {
    display: flex;
    margin-bottom: px2vw(32);

    .button-bg-transparent,
    .button-primary-white {
      display: inline-flex;
      font-size: px2vw(32);
      align-items: center;
      justify-content: center;
      // margin-top: px2vw(64);
      width: px2vw(320);
      height: px2vw(80);
    }

    .button-bg-transparent {
      display: inline-flex;
      font-size: px2vw(32);
      align-items: center;
      justify-content: center;
      height: px2vw(80);
      background: transparent;
      border: px2vw(4) solid #fff;
    }

    .button-primary-white {
      margin-left: px2vw(40);
    }
  }
}
</style>
