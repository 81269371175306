/**
 * 新分免费试听课页面 配置
 */
export default {
  banner: {
    sg: {
      bannerImg: 'sg-free-trial-class',
      title: 'FREE Trial Lesson',
      description: `Experiencing is believing - let your child try our uniquely effective dual-teacher, edtech-powered live online Math classes for free! 
      Parents are welcome to observe the class and discuss with the Guiding Teacher after the trial lesson.`,
    },
    cn: {
      bannerImg: 'sg-free-trial-class',
      title: '免费试听课',
      description: '体验感即是说服力---让您的孩子体验我们独特的，双师模式，并由强大的教育科技驱动的在线数学课！我们欢迎家长观摩课堂，并在试听课后与辅导老师讨论沟通。',
    },
  },
  platformIntro: {
    sg: {
      title: `<p style="margin-bottom: 40px;">Real-Time Immersive Live Online Lessons</p>
      <p>for Fun & Effective Math Learning</p>`,
      picIntroList: [
        {
          picture: 'platform-intro-1',
          title: 'Plenty of quality live interactions with teachers and friends',
          description: '',
        },
        {
          picture: 'choose-interactive-2',
          title: 'Answer questions and win coins for a fun, motivating and rewarding learning journey!',
          description: 'Earn coins while answering questions during class, and exchange coins for lovely gifts to express appreciation to teachers and friends!',
        },
        {
          picture: 'main-teacher-3',
          title: 'Real-time, data-driven assessment of your child’s learning',
          description: `Live question and answer opportunities serve as learning checkpoints, and provide your child’s Master Teacher and Guiding Teacher with real-time data to assess your child’s understanding, 
        provide further guidance after the live lesson and keep the live lesson at a suitable learning pace.`,
        },
        {
          picture: 'textbook-4',
          title: 'Complementary printed and digital lesson materials, including pre-lesson and post-lesson homework',
          description: 'Printed lesson materials complement your child’s live online lesson experience – delivered right to your home for your convenience*!',
        },
        {
          picture: 'platform-intro-5',
          title: 'Past Live Lesson Video Playbacks',
          description: `Our Think Academy Student App works on most desktops, mobile phones and tablets so that learning can happen at your convenience anytime, 
        anywhere.Watch video playbacks via the App for up to 6 months after the live lesson ends! It\'s a great way to recap and revise!`,
        },
      ],
    },
    cn: {
      title: '<p style="margin-bottom: 40px; font-size: 36px;">实时沉浸式在线课程——为了更有趣，有效的数学学习</p>',
      picIntroList: [
        {
          picture: 'platform-intro-1',
          title: '与老师和伙伴们进行实时的在线互动',
          description: '',
        },
        {
          picture: 'choose-interactive-2',
          title: '回答问题，赚取金币，度过一段有趣、有动力、有收获的学习之旅!',
          description: '在课堂上回答问题的同时赚取金币，用金币换取可爱的礼品，激发孩子学习兴趣和动力',
        },
        {
          picture: 'main-teacher-3',
          title: '实时的学习数据 全面对您孩子的学习进行评估',
          description: '实时检测孩子学习情况，为您孩子的主讲教师和辅导老师提供实时数据，评估孩子对知识点的理解。课程结束后辅导老师对孩子的学习提供进一步的指导，科技和爱心让孩子的学习更高效。',
        },
        {
          picture: 'textbook-4',
          title: '纸质版和电子版课程材料，包括课前和课后的作业。',
          description: '纸质版教材是对您孩子在线课程课堂体验的补充--直接送到您的家中',
        },
        {
          picture: 'platform-intro-5',
          title: '往期直播课程视频回放',
          description: '我们的自主研发上课软件可以在电脑、手机和平板上使用，这样就可以在你方便的时候，随时随地学习。可以在直播课程结束后的6个月内通过app观看视频回放！助力孩子随时复习，温故知新！',
        },
      ],
    },
  },
  dualTeacher: {
    sg: {
      title: `<p>360°</p>
      <p style="font-size: 32px;">Quality Dual-Teacher & EdTech-Powered Learning Experience</p>`,
      introList: [
        'Parent-teacher phone conversation before your child’s first lesson to discuss his or her learning needs and goals',
        'Pre-lesson activity for your child to complete before each lesson',
        'Live broadcast online learning by a Master Teacher with close supervision by a Guiding Teacher',
        'Active participation during the live class with opportunities for teacher-child interactions and interactive and engaging questions',
        'Enjoy free video playbacks of the Master Teacher’s live lessons for up to 6 months to recap and revise',
        'Complete homework which are marked by the Guiding Teacher with learning feedback for improvement provided',
        'Dedicated 1-to-1 teacher-child consultation session to review your child’s performance, progress and areas to keep improving on',
        'Regular communications with the Guiding Teacher before, during and after class where needed',
      ],
    },
    cn: {
      title: ` <p>360°</p>
      <p style="font-size:40px;">优质双师服务和科技带来的高效学习体验</p>`,
      introList: [
        '在您孩子的第一节课前，老师与家长进行电话交谈，沟通孩子的学习需求和目标',
        '每节课前陪您的孩子做课前活动',
        '在辅导老师的监督下，与主讲老师一起进行在线学习',
        '在直播课堂上积极参与进行师生互动，并让孩子提出互动和参与性问题',
        '在长达6个月的时间里，免费享受名师直播课程的视频回放，以便复盘和复习',
        '辅导老师批改家庭作业，并提供学习反馈，以便改进',
        '专门的1对1家长会，以回顾您孩子的表现、进展和需要继续改进的地方',
        '在课前、课中和课后与辅导老师进行定期沟通',
      ],
    },
  },
  freeTrialClasses: {
    sg: {
      title: 'Sign up for one of the upcoming free trials below!',
      filterTitle: 'My child is',
    },
    cn: {
      title: '选择您孩子的年级并预订免费的数学试听课',
      filterTitle: '我的孩子是',
    },
  },
  getNotifiedConfig: {
    sg: {
      title: 'No suitable free trial timings available?',
      description: 'Get notified via WhatsApp when new free trial timings are available!',
      form: {
        grade: 'Your child\'s grade',
        phone: 'WhatsApp Mobile Number',
        email: 'Enter Your Email',
        platform: 'How did you find out about us?',
      },
      notice: 'Sign up for our mailing list to receive the latest news, promotions and updates!',
      submit: 'Submit',
    },
    cn: {
      title: '没有合适的免费课试听时间？',
      description: '当您有空时，请通过WhatsApp通知我们!',
      form: {
        grade: '您孩子的年级：',
        phone: '您的电话号码：',
        email: '您的邮箱地址：',
        platform: '您是怎么了解到我们的呢？',
      },
      notice: '同意我们通过邮件给您发送最新的咨询，促销活动和更新！',
      submit: '提交',
    },
  },
  enrollNow: {
    sg: {
      btnText: 'Enroll Now',
      content: 'Sign up for our free trial lesson!',
    },
    cn: {
      btnText: '现在报名',
      content: '快来报名我们的免费试听课！',
    },
  },
  exceptionContent: {
    sg: 'There are no upcoming free trials at the moment!',
    cn: '暂时没有即将开课的免费试听课',
  },
}
