<template>
  <div :id="localeClass">
    <template v-if="$nuxt.isOffline">
      <Header :show-sign-in="false" />
      <div class="network-offline-container">
        <Exception tip-text="Loading failed, please check your connection." placeholder-class-name="system-error-img" :show-retry-btn="true" @retry="reload" />
      </div>
      <Footer />
    </template>
    <template v-else-if="$nuxt.isOnline">
      <nuxt />
      <!-- <CookiesStatement /> -->
      <!-- 新分联系我们icon -->
      <WhatsAppIcon v-if="$store.state.locale === 'sg'" />
    </template>
    <UnpaidDialog ref="unpaidDialog" />
    <CompleteStuInfoPopup ref="coursesDetailCompleteStuInfo" :trigger-args="completeStuInfoTriggerArgs" />
    <CookiesAccept v-if="$store.state.locale === 'uk'" />
    <BindDialog />
  </div>
</template>

<script>
import bus from 'common/bus';
import headMixin from 'mixins/layout/head';
import Header from '@/components/global/FeatureComponents/Header/touch.vue';
import Footer from '@/components/global/FeatureComponents/Footer/touch.vue'
import Exception from 'components/common/Exception/index.vue';
import UnpaidDialog from 'components/common/UnpaidDialog/index.vue';
import WhatsAppIcon from 'components/common/WhatsAppIcon/index.vue';
import CompleteStuInfoPopup from 'components/login/CompleteStuInfoPopup/index.vue';
// import CookiesStatement from 'components/common/CookiesStatement/index.vue';
import CookiesAccept from 'components/common/CookiesAccept/index.vue';
import BindDialog from 'components/common/bindDialog/index.vue';

export default {
  components: {
    BindDialog,
    Header,
    Exception,
    // CookiesStatement,
    CookiesAccept,
    Footer,
    UnpaidDialog,
    CompleteStuInfoPopup,
    WhatsAppIcon,
  },
  mixins: [headMixin],
  data() {
    return {
      completeStuInfoTriggerArgs: null,
    }
  },
  computed: {
    /**
     * 当前语言环境样式名
     * 可通过此样式名叠加样式实现不同语言样式
     */
    localeClass() {
      return `locale__${this.$store.state.locale}`;
    },
  },
  watch: {
    '$nuxt.isOffline': function watchIsOffline() {
      this.$Dialog.close();
    },
  },
  mounted() {
    this.redirect()
    // 页面刷新强制跳转到页面顶部
    document.documentElement.scrollTop = 0;
    this.$nextTick(() => {
      bus.$on('openUnpaidDialog', () => {
        this.openUnpaidDialog();
      });
      bus.$on('complete-stu-info', (argsObj) => {
        this.completeStuInfoTriggerArgs = { ...argsObj }
        this.openCompleteStudentInfoDialog(argsObj);
      });
    })
  },
  methods: {
    reload() {
      if (this.$nuxt.isOffline) return;
      window.location.reload();
    },
    openUnpaidDialog() {
      if (this.$refs.unpaidDialog) this.$refs.unpaidDialog.openDialog();
    },
    openCompleteStudentInfoDialog(argsObj) {
      if (this.$refs.coursesDetailCompleteStuInfo) this.$refs.coursesDetailCompleteStuInfo.openPicker(argsObj);
    },
    // 美分重定向
    redirect() {
      const { fullPath } = this.$route;
      const link = 'https://youthmath.org/'
      if (fullPath.includes('/world-think-cup')) {
        window.open(link, '_blank')
      } else if (fullPath.includes('/math-reward-challenge')) {
        window.location.href = link
      }
    },
  },
  head() {
    return {
      ...headMixin.head.call(this),
      htmlAttrs: {
        id: `style__${this.$store.state.betterme}`,
      },
      link: [{
        hid: 'icon',
        rel: 'icon',
        type: 'image/x-icon',
        href: this.$store.state.betterme === 'bettermeedu' ? 'https://download-pa-s3.thethinkacademy.com/static-pa/project/game/assets/favicon.ico' : '/favicon.ico',
      }],
    }
  },
}
</script>

<style lang="scss">
.network-offline-container {
  padding: px2vw(110) 0;
}
</style>
