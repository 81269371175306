<template>
  <van-popup
    :value="visible"
    :close-on-click-overlay="false"
    class="bind-dialog"
    :round="true"
  >
    <div v-if="visible" class="dialog-header">
      <div v-if="isSend" class="operation-icon back-icon" @click="backFunc" />
      <div class="operation-icon close-icon" @click="closeHandler" />
    </div>
    <div v-if="visible" class="dialog-body">
      <div v-if="type=='phone'">
        <div v-if="!isSend" class="dialog-title font-semibold">
          {{ $t('bindForm.phone.title') }}
        </div>
        <div v-if="!isSend" class="subTitle font-medium">
          {{ $t('bindForm.phone.subTitle') }}
        </div>

        <div v-if="isSend" class="dialog-title font-semibold">
          {{ $t('login.login.confirmTitleForPhone') }}
        </div>
        <div v-if="isSend && locale !== 'jp'" class="subTitle font-medium marginReset" v-html="$t('login.login.confirmSubTitleForPhone')" />
        <div v-if="isSend" class="code-style font-medium">
          {{ valueCode }}
        </div>
        <div v-if="isSend && locale === 'jp'" class="subTitle font-medium marginReset" v-html="$t('login.login.confirmSubTitleForPhone')" />
      </div>
      <div v-if="type=='email'">
        <div v-if="!isSend" class="dialog-title font-semibold">
          {{ $t('bindForm.email.title') }}
        </div>
        <div v-if="!isSend" class="subTitle font-medium">
          {{ $t('bindForm.email.subTitle') }}
        </div>

        <div v-if="isSend" class="dialog-title font-semibold">
          {{ $t('login.login.confirmTitleForEmail') }}
        </div>
        <div v-if="isSend" class="subTitle font-medium marginReset" v-html="$t('login.login.confirmSubTitleForEmail')" />
        <div v-if="isSend" class="code-style font-medium">
          {{ valueCode }}
        </div>
      </div>

      <!-- 手机号表单 -->
      <PhoneForm v-if="type=='phone'" ref="phoneForm" :execute="execute" :guide="guide" :scene="3" class="form-width" cookie-name="bind-phone-count" :can-switch-type="false" :is-loging="isLoging" @submit="submitHandle" @titleChange="titleChange" />
      <!-- 邮箱表单 -->
      <EmailForm v-if="type=='email'" ref="emailForm" :execute="execute" :guide="guide" :scene="3" class="form-width" cookie-name="bind-email-count" :can-switch-type="false" :is-loging="isLoging" @submit="submitHandle" @titleChange="titleChange" />
    </div>
  </van-popup>
</template>

<script>
import PhoneForm from 'components/login/newLoginForm/phoneForm.vue'
import EmailForm from 'components/login/newLoginForm/emailForm.vue';
import bus from 'common/bus.js';
import { oneApi } from 'config/api'
import { getLoginAccountType } from 'common/login.js';

export default {
  components: {
    PhoneForm, EmailForm,
  },
  data() {
    const { studentId } = this.$route.query;
    return {
      studentId,
      isSend: false,
      isLoging: false,
      valueCode: '',
    }
  },
  computed: {
    visible() {
      return this.$store.state.bindDialog.visible
    },
    type() {
      return this.$store.state.bindDialog.type
    },
    guide() {
      return this.$store.state.bindDialog.guide
    },
    execute() {
      return this.$store.state.bindDialog.execute
    },
    locale() {
      return this.$store.state.locale
    },
  },
  watch: {
    visible(val) {
      if (val) {
        // 展示时埋点
        if (!this.isSend) {
          this.eventTrack('associate_pop_pv')
        } else {
          this.eventTrack('associate_confirm_pop_pv')
        }
      } else {
        this.$store.dispatch('bindDialog/close')
      }
    },
  },
  methods: {
    eventTrack(eventName) {
      this.Sensors.track(eventName, {
        associate_type: getLoginAccountType(this.type === 'phone' ? 0 : 3),
      })
    },
    closeHandler() {
      this.isSend = false;
      this.isLoging = false;
      this.valueCode = '';
      this.$store.dispatch('bindDialog/close')
    },
    backFunc() {
      if (this.type === 'phone') {
        this.$refs.phoneForm.init();
      } else {
        this.$refs.emailForm.init();
      }
      this.isSend = false;
    },
    titleChange(obj) {
      this.eventTrack('associate_confirm_pop_pv')
      this.isSend = obj.isSend
      this.valueCode = obj.valueCode
    },
    async submitHandle(obj) {
      this.eventTrack('associate_confirm_pop_click')
      let data = {}
      let res;
      if (this.type === 'phone') {
        data = {
          phone: obj.phone,
          verificationCode: obj.captcha,
          countryCallingCode: obj.phoneAreaCode.value,
        }
        res = await this.$axios.post(oneApi.phoneModify, data, { rewritePostBody: true, uid: this.studentId })
      } else {
        data = {
          email: obj.email,
          verificationCode: obj.captcha,
        }
        res = await this.$axios.post(oneApi.emailModify, data, { rewritePostBody: true, uid: this.studentId })
      }
      // 触发后续购课流程
      if (Number(res.code) !== 0) {
        this.$Toast(res.msg)
        return;
      }
      this.$store.dispatch('ucenter/queryUserBasicInfo', { $axios: this.$axios, userData: { fillInAttention: false } });
      this.closeHandler();
      if (this.$route.name === 'my-unpaid-courses-list') {
        bus.$emit('unpaid.list.registrationCourses', { uid: this.studentId })
      }
      bus.$emit('courses.detail.registrationCourses', false)
    },
  },
}
</script>

<style lang="scss" scoped>
.bind-dialog {
  border-radius: px2vw(32);

  /deep/.phone-input {
    margin-bottom: px2vw(80);
  }

  /deep/.captcha-input {
    margin-bottom: px2vw(80);
  }

  /deep/.email-input {
    margin-bottom: px2vw(80);
  }
}

.dialog-header {
  position: relative;
  height: px2vw(110);

  .operation-icon {
    width: px2vw(64);
    height: px2vw(64);
    position: absolute;
    top: px2vw(20);
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }

  .back-icon {
    left: px2vw(20);
    background-image: url('~assets/images/common/iconback.png');
  }

  .close-icon {
    right: px2vw(20);
    background-image: url('~assets/images/common/iconclose.png');
  }
}

.dialog-body {
  padding: 0 px2vw(32);

  .dialog-title {
    font-size: px2vw(36);
    color: var(--txt-color-lv1);
    line-height: px2vw(42);
    margin-bottom: px2vw(32);
    text-align: center;
  }

  .subTitle {
    font-size: px2vw(32);
    color: var(--txt-color-lv2);
    line-height: px2vw(38);
    margin-bottom: px2vw(48);
    text-align: center;
  }

  .marginReset {
    margin-bottom: px2vw(16);
  }

  .code-style {
    font-size: px2vw(32);
    color: var(--txt-color-lv1);
    line-height: px2vw(38);
    text-align: center;
    margin-bottom: px2vw(48);
  }

  .form-width {
    margin: 0 auto;
    width: px2vw(622);
  }
}
</style>
