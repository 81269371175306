/* eslint-disable max-len */
/**
 * App专用faq配置
 */
export default {
  reviewList: [
    {
      question: '學而思的課程涵蓋什麼內容？',
      answer: '香港學而思為K3至P6的學生提供奧數課程。學而思設有沉澱了17年的數學體系。整個課程體系涵蓋校內90%的內容，並且老師會引導小朋友用更加巧妙的方法解決問題；除此之外，我們的課程體系涵蓋大部分熱門數學比賽的題目板塊及內容。',
    },
    {
      question: '小朋友報讀學而思的優勢',
      answer: '通過堅持在學而思學習，小朋友既能在校內數學考試應對自如，又能在校外盃賽奪得頭獎，更能獲得受益終身的思維能力。學而思堅持不懈的，不只是做知識的傳授者，更要做小朋友成長道路上可貴能力的培養者。',
    },
    {
      question: '一堂課有90或120分鐘，小朋友可以做到一直保持專注嗎？',
      answer: '通過堅持在學而思學習，小朋友既能在校內數學考試應對自如，又能在校外盃賽奪得頭獎，更能獲得受益終身的思維能力。學而思堅持不懈的，不只是做知識的傳授者，更要做小朋友成長道路上可貴能力的培養者。',
    },
    {
      question: '如果某次課程臨時無法出席怎麼辦？',
      answer: `
        <p>學而思允許隨時調堂，每個班級每堂課設有額外4個調堂名額。暑期恆常班有4次調堂機會，轉班次數不限。</p>
        <p>如果某次課堂小朋友臨時無法出席，家長可以在開課前通過「學而思培優」App進行調堂，調至其他時間上堂。</p>
      `,
    },
    {
      question: '如何得知小朋友的學習進度？',
      answer: `
        <p>授課老師全程跟進小朋友的學習進度。學而思師資優良，認真負責，他們的責任心對於幫助小朋友掌握知識、養成良好學習習慣至關重要。
        學而思老師的跟進過程：</p>
        <p>1）課前環節——帶學生複習上一節課內容，針對課後功課的普遍問題進行講解輔導；</p>
        <p>2）課中環節——引導學生積極參與課堂，跟進小朋友的吸收程度，及時調整課堂節奏；</p>
        <p>3）課後環節——及時回答學生疑問，批改課後功課並給予反饋，與家長保持溝通。</p>
      `,
    },
    {
      question: '為什麼學而思的學生那麼喜歡學習？',
      answer: `
        <p>學而思的課堂中設計互動環節的本質是要增強小朋友在課堂上的參與感，從而更加主動地汲取知識。所以，學而思的小朋友在課堂上更積極投入，是因為我們的課堂會通過以下方式增加小朋友參與感：</p>
        <p>1）生動課件</p>
        <p>融入故事情節、動畫場景，增加課堂的趣味性</p>
        <p>2）鼓勵機制</p>
        <p>積分獎勵系統、MVP榜、做題之星等激發學生內心學習動機</p>
        <p>3）趣味遊戲</p>
        <p>設置課間遊戲環節，放鬆之餘，調動小朋友學習積極性</p>
      `,
    },
  ],
}
