<template>
  <div>
    <BottomPopup :show="showGradeSelect" @right-icon-handler="onConfirm" @left-icon-handler="handleCancel">
      <template slot="custom-body">
        <div>
          <van-picker
            :columns="gradeList"
            :default-index="currentSelectedIndex"
            value-key="name"
            @change="changeHandler"
          />
        </div>
      </template>
    </BottomPopup>
  </div>
</template>

<script>

import BottomPopup from 'components/common/BottomPopup/index.vue';

export default {
  components: { BottomPopup },
  props: {
    defaultIndex: {
      default: 0,
      type: Number,
    },
    gradeList: {
      default: () => [],
      type: Array,
    },
  },
  data() {
    return {
      showGradeSelect: false,
      currentSelectedIndex: this.defaultIndex,

    }
  },
  methods: {
    open() {
      this.currentSelectedIndex = this.defaultIndex;
      this.showGradeSelect = true;
    },
    close() {
      this.showGradeSelect = false;
      this.$emit('close-callback');
    },
    changeHandler(instance, selectedValue, selectedIndex) {
      this.currentSelectedIndex = selectedIndex;
    },
    handleCancel() {
      this.currentSelectedIndex = this.defaultIndex;
      this.close();
    },
    onConfirm() {
      if (this.currentSelectedIndex < 0) return;
      this.$emit('grade-change', this.currentSelectedIndex)
      this.close();
    },
  },
}
</script>
