<template>
  <div :id="localeClass">
    <nuxt />
  </div>
</template>

<script>

export default {
  data() {
    return {

    }
  },
  computed: {
    /**
     * 当前语言环境样式名
     * 可通过此样式名叠加样式实现不同语言样式
     */
    localeClass() {
      return `locale__${this.$store.state.locale}`;
    },
  },
  mounted() {
    // 页面刷新强制跳转到页面顶部
    document.documentElement.scrollTop = 0;
  },
  head() {
    return {
      htmlAttrs: {
        id: `style__${this.$store.state.betterme}`,
      },
      link: [{
        hid: 'icon',
        rel: 'icon',
        type: 'image/x-icon',
        href: this.$store.state.betterme == 'bettermeedu' ? 'https://download-pa-s3.thethinkacademy.com/static-pa/project/game/assets/favicon.ico' : '/favicon.ico',
      }],
    }
  },
}
</script>
