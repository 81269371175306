import semver from 'semver'
import Cookies from 'js-cookie'

const appVersion = Cookies.get('_app_version')
const appDevice = Cookies.get('_app_device')
const appName = Cookies.get('_app_name')

// 系统版本
// 将版本号强制转换为 x.x.x 格式
const systemVersion = Cookies.get('_app_system') ? semver.coerce(Cookies.get('_app_system')).version : ''

// 判断课程列表页是否是原生版本
const isNativeCourses = () => {
  if (!appVersion) return false
  const nativeCoursesVersion = '2.4.1' // 原生课程列表页客户端版本号
  return semver.gte(appVersion, nativeCoursesVersion)
}

// 检测是否支持原生支付
// 大于等于2.5.5版本，并且美分走原生支付，其它分校走H5支付
const isNativePay = ({ local = '' }) => {
  if (!appDevice || !appVersion || !systemVersion) return false
  const partNativePayVersion = '2.5.5' // 部分支持原生支付的客户端版本号
  const nativePayVersion = '2.7.0' // 全部分校支持原生支付的客户端版本号
  const iosMinVersion = '11.0.0' // 支持原生支付的iOS系统最低版本号, 包括当前配置版本

  // Think Parent 支持原生支付
  if (appName === 'thinkParent') {
    return true
  }

  // ios且系统版本小于iosMinVersion, 则不允许原生支付
  if (appDevice.toLowerCase() === 'ios' && semver.lt(systemVersion, iosMinVersion)) {
    return false
  }

  // Think Chinese分校不支持原生支付
  if (local === 'tc' || local === 'ca') {
    return false
  }

  // 2.7.0及以上版本所有分校支持原生支付
  if (semver.gte(appVersion, nativePayVersion)) {
    return true
  }

  // 2.7.0以下版本处理逻辑
  // 原生支付只支持美分、火星、马亚西亚、澳大利亚、加拿大
  const payLocalList = ['us', 'cn', 'my', 'au', 'ca']
  if (!payLocalList.includes(local)) {
    return false
  }
  // 美分、火星原生支付条件
  // App版本小于partNativePayVersion, 则不允许原生支付
  if (semver.lt(appVersion, partNativePayVersion)) {
    return false
  }

  return true
}

export {
  isNativeCourses,
  isNativePay,
}
