/**
 * terms-of-refund 页面配置
 */

export default {
  title: '退費協定',
  content: `
    <p class="font-bold">1. 退款計算方法：</p>
    <p>1.1 恆常班第二堂課上課前退款，全額退回；第二堂上課後退款，則按剩餘課次退還。</p>
    <p>1.2 精講班及其他特殊班次之退費以本校開班的說明為準。</p>
    <p class="font-bold">2. 學費退款說明：</p>
    <p>2.1 學生需要在下一堂課上課前辦理退款，已過上課時間將不予退款。</p>
    <p>2.2 學生自身原因缺席將不予退款。</p>
    <p>2.3 所有課程退款需由家長親自到接待處辦理，同時需出示有效證明文件（身份證或護照、學生證）。</p>
    <p>2.4 該學期已結課後將不予退款。</p>
    <p class="font-bold">3. 書籍費退款說明：</p>
    <p>3.1 學生如未領取書本及教材，書籍費可全額退回。</p>
    <p>3.2 學生凡領取書本及教材後，書籍費將不予退回。</p>
    <p>3.3 凡書本及教材在本校寄出後，書籍費將不予退回。(僅適用於學而思在線課學生)</p>
    <p>註2：實際書本寄出時間以分校通知為準</p>
    <p>*學而思教育科技（香港）有限公司保留最終解釋權</p>
    <p class="font-bold">退款方式：</p>
    <p>退款時，剩餘款項會退還到對應的支付賬號或銀行戶口。（約7個工作天到賬）</p>
  `,
}
