/**
 * 提供APP与H5通信插件
 */
import Vue from 'vue';
import bus from '../common/bus';
import { printLog } from '../common/utils';

const { NODE_ENV } = process.env
const isLocal = NODE_ENV === 'local'
const isApp = !!window.JsInjectionActivity

Vue.prototype.$bus = bus;
Vue.prototype.$setAppTitle = (data) => bus.$emit('app-v2.setTitleBar', data)
Vue.prototype.$transformLocalPath = (path, openWebview, router, callback) => {
  if (isLocal || !openWebview) {
    if (router) {
      if (callback) {
        callback(router)
      } else {
        router.push(path)
      }
    } else {
      window.location.href = path
    }
  } else if (path === '/courses') {
    bus.$emit('app-v2.jumpStore')
  } else {
    bus.$emit('app-v2.openURL', { url: router ? `${window.location.origin}${path}` : path })
  }
}
Vue.prototype.$openWebview = (path, router, isOpen = true) => {
  if (!path) return;

  if (isLocal) {
    if (isOpen) {
      window.open(path, '_blank');
    } else {
      window.location.href = path
    }
  } else {
    bus.$emit('app-v2.openURL', { url: router ? `${window.location.origin}${path}` : path, closeEnabled: isOpen })
  }
}
Vue.prototype.$login = (path, router, source) => {
  if (isLocal) {
    router.replace(path)
  } else {
    bus.$emit('app-v2.login', source ? {
      source,
    } : null)
  }
}
Vue.prototype.$goBack = (callback = () => {}, fromApp = false) => {
  if (!isLocal && fromApp) {
    bus.$emit('app-v2.close')
  } else {
    callback()
  }
}
Vue.prototype.$hwAppPush = (params, fromApp = false) => {
  if (!isLocal && fromApp) {
    bus.$emit('app-v2.hwAppPush', params)
  }
}
Vue.prototype.$jumpFamilyAccount = (fun) => {
  if (!isApp) {
    // router.replace('/settings')
    fun.push('/my-account')
  } else {
    bus.$emit('app-v2.jumpFamilyAccount')
  }
}
Vue.prototype.$shareContent = (shareContent, shareTitle) => bus.$emit('app-v2.shareContent', { shareContent, shareTitle })
Vue.prototype.$makeCall = (phone, $event) => {
  if (!isLocal) {
    $event.preventDefault();
    bus.$emit('app-v2.makeCall', { phone })
  }
}
Vue.prototype.$isLocal = isLocal;

export default () => {
  [
    'jsGetNaviteParame',
    'makeCall',
    'jumpMyOrder',
    'jumpFamilyAccount',
    'jumpStore',
    'jumpCourseList',
    'close',
    'shareContent',
    'openURL',
    'hideTitleBar',
    'setTitleBar',
    'login',
    'goback',
    'switchAccount',
    'pageLoadComplete', // 页面加载完成
    'hwAppPush',
    'goLiveClassDetail',
  ].forEach((methodName) => {
    bus.$on(`app-v2.${methodName}`, (params) => {
      const jsonData = {
        className: 'HWTouchStore',
        methodName,
      }
      if (params) {
        jsonData.params = params
      }
      printLog('H5 Call Client LOG', jsonData);
      window.JsInjectionActivity.jsCallClient(JSON.stringify(jsonData));
    })
  })

  // 购课成功后通知原生App购课成功
  bus.$on('app-v2.buyCourseSuccess', () => {
    const jsonData = JSON.stringify({
      className: 'HWTouchStore',
      methodName: 'buyCourseSuccess',
    });
    printLog('H5 Call Client LOG', jsonData);
    window.JsInjectionActivity.jsCallClient(jsonData);
  });

  /**
  * IOS苹果内购
  */
  bus.$on('app-v2.iOSPay', (data) => {
    const jsonData = JSON.stringify({
      className: 'HWTouchStore',
      methodName: 'IAPLoad:',
      params: {
        orderid: data.orderId,
        productid: data.courseId,
      },
    });
    printLog('H5 Call Client LOG', jsonData);
    window.JsInjectionActivity.jsCallClient(jsonData);
  });

  /**
  * 订单支付
  */
  bus.$on('app-v2.orderPay', (data) => {
    const jsonData = JSON.stringify({
      className: 'HWTouchStore',
      methodName: 'orderPay',
      params: {
        orderid: data.orderId,
        studentId: data.studentId,
      },
    });
    printLog('H5 Call Client LOG', jsonData);
    window.JsInjectionActivity.jsCallClient(jsonData);
  });
}
