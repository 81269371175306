/**
 * faculty配置
 */
export default {
  // 教师数据
  teacherData: {
    'Jason Ang': {
      id: '2',
      photoUrl: 'https://download-pa-s3.thethinkacademy.com/static-pa/static/node_global_website_online/sg/touch/teachers/Jason_Ang.jpg',
      name: 'Jason Ang',
      teachingInfo: 'Math Master Teacher',
      hashTags: [
        'NTU Honours Degree',
        'Math Award Winner',
        'Experienced Tutor',
      ],
      intros: [
        'Nanyang Technological University Bachelor of Science (Honours)',
        'Distinctions in math competitions',
        'Over 4 years of teaching experience',
      ],
    },
    'Anthea Lim Jinwen': {
      id: '3',
      photoUrl: 'https://download-pa-s3.thethinkacademy.com/static-pa/static/node_global_website_online/sg/touch/teachers/Anthea_Lim_Jinwen.jpg',
      name: 'Anthea Lim Jinwen',
      teachingInfo: 'Math Master Teacher',
      hashTags: [
        'NUS Honours Degree',
        'Lee Kuan Yew Award Winner',
        'Experienced Tutor',
      ],
      intros: [
        'National University of Singapore Bachelor of Engineering (Honours)',
        'Lee Kuan Yew Award winner',
        'Over 5 years of teaching experience',
      ],
    },
    'Runze (Crystal)': {
      id: '4',
      photoUrl: 'https://download-pa-s3.thethinkacademy.com/static-pa/static/node_global_website_online/sg/touch/teachers/Runze.jpg',
      name: 'Runze (Crystal)',
      teachingInfo: 'Math Master Teacher',
      hashTags: [
        'NTU Master and Honours Degree',
        'Scholarship Recipient',
        'International Math Competition Winner',
      ],
      intros: [
        'Nanyang Technological University Masters of Finance, Bachelor of Mathematical Sciences (Honours)',
        'International and national math competition award winner (CGMO and CMO Silver Medal, AMC and AIME Perfect Score)',
        'Over 2 years of teaching experience',
      ],
    },
    Wiya: {
      id: '5',
      photoUrl: 'https://download-pa-s3.thethinkacademy.com/static-pa/static/node_global_website_online/sg/touch/teachers/Wiya.jpg',
      name: 'Wiya',
      teachingInfo: 'Math Master Teacher',
      hashTags: [
        'Top University Honours Graduate',
        'Experienced Teacher',
        'Award Winning Teacher',
      ],
      intros: [
        'Top five China university\'s Bachelor of Sciences (Honours)',
        'Over 6 years of teaching experience',
        'Think Academy Top 10 Dedicated Teachers award winner',
      ],
    },
    'Belinda Zhang': {
      id: '6',
      photoUrl: 'https://download-pa-s3.thethinkacademy.com/static-pa/static/node_global_website_online/sg/touch/teachers/Belinda_Zhang.jpg',
      name: 'Belinda Zhang',
      teachingInfo: 'Math Master Teacher',
      hashTags: [
        'Princeton University Bachelor of Arts',
        'Lee Kuan Yew Award Winner',
        'Math Olympiad Winner',
      ],
      intros: [
        'Princeton University\'s Bachelor of the Arts in Economics, Certificate in Finance',
        'Lee Kuan Yew Award for Mathematics and Science',
        'Singapore International Mathematical Olympiad training team member',
      ],
    },
    'Wanying Guo': {
      id: '7',
      photoUrl: 'https://download-pa-s3.thethinkacademy.com/static-pa/static/node_global_website_online/sg/touch/teachers/Wanying_Guo.jpg',
      name: 'Wanying Guo',
      teachingInfo: 'Math Master Teacher',
      hashTags: [
        'University of Oxford, Masters in Education',
        '3 years of tutoring experience',
      ],
      intros: [
        'University of Oxford, MSc Education (Child Development and Education)',
        'University College London, BSc Psychology',
      ],
    },
  },
}
