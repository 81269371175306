<template>
  <div v-if="!$store.state.appDevice && showFooter" class="footer font-medium" :class="`footer-${$store.state.locale}`">
    <div class="wrapper">
      <div class="module-item brand" style="overflow: hidden;">
        <div class="logo" :class="`logo-${$store.state.locale}`">
          <img v-lazy-load :data-src="logoUrl" :alt="$t('seo.common.logoDescription')" :title="$t('common.websiteName')" loading="lazy">
        </div>
        <div v-if="$t('common.footerOtherInfo').length > 0" class="other-info">
          <div v-for="(otherInfo, index) in $t('common.footerOtherInfo')" :key="index" class="wasc-auth">
            <a v-if="otherInfo.link" :href="otherInfo.link" target="_blank">
              <img class="wasc-icon" :height="otherInfo.iconHeight" v-lazy-load :data-src="otherInfo.icon" alt="" loading="lazy">
            </a>
            <img v-else class="wasc-icon" :height="otherInfo.iconHeight" v-lazy-load :data-src="otherInfo.icon" alt="" loading="lazy">
            <p class="wasc-name" v-html="otherInfo.name" />
          </div>
        </div>
      </div>
      <template v-if="showDetail">
        <div class="module-item links">
          <div class="links-group-item about-us">
            <div class="title">
              {{ aboutUs.title }}
            </div>
            <template v-for="(item, index) in aboutUs.links">
              <a v-if="item.target" :key="index" class="hover-transition" rel="noopener" :href="item.url" :target="item.target">{{ item.name }}</a>
              <nuxt-link v-else :key="`nuxt-link-${index}`" class="hover-transition" :to="item.url" :target="item.target" rel="noopener">
                {{ item.name }}
              </nuxt-link>
            </template>
          </div>
          <!-- 联系方式模块 -->
          <div v-if="Object.keys(contactUsConfig).length" class="links-group-item contact font-medium">
            <div class="title">
              {{ contactUsConfig.title }}
            </div>
            <!-- 邮箱&电话号 -->
            <template v-if="contactUsConfig.displayType == 'contact'">
              <div v-if="contactList.length && locale !== 'tm'" class="item-group">
                <div v-for="(item, index) in contactList" :key="index" class="contact-item">
                  <div class="item-title" v-html="item.title" />
                  <div class="item-content" v-html="item.content" />
                  <div v-if="item.footer" v-html="item.footer" />
                </div>
              </div>
              <div v-else class="item-group">
                <a v-if="showContactEmail && contactEmail" class="alink-with-icon" rel="noopener" :href="`mailto:${contactEmail}`">
                  <i class="iconfont icon-mail2" /><span>{{ contactEmail }}</span>
                </a>
                <a v-if="contactPhone && locale !== 'tm'" class="alink-with-icon" rel="noopener" :href="`tel:${contactPhone}`" @click="$makeCall(contactPhone, $event)">
                  <i class="iconfont icon-phone2" /><span>{{ contactPhoneString || contactPhone }}</span>
                </a>
              </div>
            </template>
            <!-- 地址联系方式 -->
            <template v-else-if="contactUsConfig.displayType == 'address'">
              <div class="item-group address-item-group">
                <div v-for="(address, index) in contactUsConfig.data" :key="index" class="address-item">
                  <div class="title">
                    {{ address.title }}
                  </div>
                  <div class="desc phone">
                    <i class="iconfont icon-telephone-solid" /><span>{{ address.phone }}</span>
                  </div>
                  <div class="desc open-time">
                    <i class="iconfont icon-take-class-time-solid" /><span>{{ address.openTime }}</span>
                  </div>
                  <div class="desc address-desc">
                    <i class="iconfont icon-location-solid" /><span>{{ address.address }}</span>
                  </div>
                </div>
              </div>
            </template>
            <!-- 电话&工作时间 -->
            <template v-if="contactUsConfig.displayType == 'workTime'">
              <div class="links-group-item contact">
                <div class="item-group">
                  <div v-if="contactPhone" class="contact-with-icon">
                    <i class="iconfont icon-phone2" /><span>{{ contactPhone }}</span>
                  </div>
                  <div v-if="workingTime" class="contact-with-icon">
                    <i class="iconfont icon-take-class-time-solid" /><span>{{ workingTime }}</span>
                  </div>
                </div>
              </div>
            </template>
          </div>
          <div v-if="followsLinks.length > 0" class="links-group-item follow">
            <div class="title">
              {{ follows.title }}
            </div>
            <div class="item-group">
              <template v-for="(follow, index) in followsLinks">
                <a v-if="follow.linkType == 'link'" :key="index" class="alink-with-icon" rel="noopener" :href="follow.link" :target="follow.target">
                  <i class="iconfont" :class="[follow.icon]" /><span class="alink-text">{{ follow.name }}</span>
                </a>

                <div v-else-if="follow.linkType == 'qrcode'" :key="`follow-v-else-${index}`" class="alink-with-icon" @click="handleQrcode(follow)">
                  <i class="iconfont" :class="[follow.icon]" /><span class="alink-text">{{ follow.name }}</span>
                </div>
              </template>
            </div>
          </div>
          <!-- 友情链接 -->
          <div v-if="otherSchools.length > 0" class="links-group-item other-school">
            <div class="title">
              {{ otherSchool.title }}
            </div>
            <div class="item-group">
              <a v-for="(item, index) in otherSchools" :key="index" class="hover-transition" rel="noopener" :href="item.url" :target="item.target">{{ item.name[locale] }}</a>
            </div>
          </div>
        </div>
      </template>
      <div class="module-item company-info">
        <!-- 协议链接 -->
        <div class="policy-links">
          <template v-for="(item, index) in policyLinks">
            <a v-if="item.target" :key="index" class="copyright-alink" :href="item.url" :target="item.target">{{ item.name }}</a>
            <nuxt-link v-else :key="`policy-link-else-${index}`" class="copyright-alink" :to="item.url">
              {{ item.name }}
            </nuxt-link>
          </template>
        </div>
        <div v-for="(companyInfoItem, companyInfoIndex) in companyInfo" :key="`companyInfoIndex-${companyInfoIndex}`" class="company-info-item">
          <template v-if="!companyInfoItem.isLink">
            <p>
              {{ companyInfoItem.text }}
            </p>
          </template>
          <template v-else>
            <a class="host-record-alink hover-transition" :href="companyInfoItem.link" target="_blank" rel="noopener">{{ companyInfoItem.text }}</a>
          </template>
        </div>
      </div>
    </div>
    <!-- 安全协议 -->
    <!-- <div v-if="locale == 'hk'" class="safety">
      <div class="safety-info-item">
        <span>本網站受reCAPTCHA保護，並適用Google</span>
        <a class="host-record-alink hover-transition" href="https://policies.google.com/privacy">隱私政策</a> <span>和</span>
        <a class="host-record-alink hover-transition" href="https://policies.google.com/terms">服務條款</a>
      </div>
    </div>
    <div v-else class="safety">
      <div class="safety-info-item">
        <span>This site is protected by reCAPTCHA and the Google</span>
        <a class="host-record-alink hover-transition" href="https://policies.google.com/privacy">Privacy Policy</a> <span>and</span>
        <a class="host-record-alink hover-transition" href="https://policies.google.com/terms">Terms of Service</a> <span>apply.</span>
      </div>
    </div> -->
    <WechatPopup />
  </div>
</template>
<script>
import bus from 'common/bus';
import WechatPopup from '../WechatPopup/touch';
import mixin from './mixin.js';

export default {
  components: {
    WechatPopup,
  },
  mixins: [mixin],
  methods: {
    handleQrcode(item) {
      bus.$emit('touch.common.WechatPopup.show', {
        qrcodeUrl: item.link,
        qrcodeWechatUrl: item.linkWechat,
        description: item.tipText,
        descriptionWechat: item.tipTextWechat,
      });
    },
  },
}
</script>
<style lang="scss" scoped>
.footer {
  color: var(--txt-color-lv1);
  background: #f5f5f5;

  .wrapper {
    padding: 0 20px;
    font-size: 14px;
    line-height: 16px;
    word-spacing: 0;
    letter-spacing: -0.4px;
  }

  .module-item {
    &:not(:last-child) {
      border-bottom: 1px solid #fff;
    }
  }

  i,
  span {
    vertical-align: middle;
  }

  a {
    display: block;
    margin: 16px 0;
    color: inherit;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .alink-with-icon {
    .iconfont {
      margin-right: 6px;
      font-size: 20px;
      line-height: 20px;
      text-align: center;
      color: inherit;
    }
  }

  .contact-with-icon {
    margin: 20px 0;

    .iconfont {
      margin-right: 6px;
      font-size: 20px;
      line-height: 20px;
      text-align: center;
      color: inherit;
    }
  }

  .module-item.brand {
    padding: 10px 0;

    .logo {
      width: 220px;
      height: 40px;
      margin: 10px 0;

      img {
        height: 40px;
      }
    }

    .other-info {
      margin: 10px 0;

      .wasc-auth {
        display: flex;
        align-items: center;
        margin-bottom: 12px;

        &:last-child {
          margin-bottom: 0;
        }

        a {
          margin: 0;
        }

        .wasc-icon {
          margin-right: 10px;
        }

        .wasc-name {
          font-size: 12px;
          line-height: 14px;
        }
      }
    }
  }

  .module-item.links {
    padding: 20px 0;

    .links-group-item:not(:last-child) {
      margin: 0 0 32px;
    }

    .title {
      font-size: 18px;
      line-height: 21px;
      font-weight: bold;
    }

    .links-group-item.contact {
      .contact-item {
        margin: 16px 0;

        .item-title {
          color: rgba(34, 34, 34, 0.4);
          margin-bottom: 4px;
        }
      }

      .address-item-group {
        .address-item {
          &:not(:last-child) {
            margin: 16px 0;
          }

          .title {
            font-size: 14px;
            line-height: 20px;
          }

          .desc {
            display: flex;
            align-items: center;
            margin: 12px 0;
            font-size: 12px;
            line-height: 14px;
            color: var(--txt-color-lv2);

            &:last-child {
              margin: 12px 0 0;
            }

            &.address-desc {
              align-items: center;
            }

            &.open-time {
              max-width: 220px;
              align-items: flex-start;
            }
          }
        }

        .iconfont {
          margin-right: 5px;
          font-size: 18px;
          line-height: 18px;
          color: inherit;
        }
      }
    }

    .links-group-item.follow {
      .item-group {
        display: flex;
        flex-wrap: wrap;

        a,
        .alink-with-icon {
          margin: 16px 30px 0 0;

          &:nth-child(3n) {
            margin: 16px 0 0 0;
          }
        }
      }
    }
  }

  .module-item.company-info {
    padding: 20px 0;
    font-size: 14px;
    line-height: 16px;
    color: rgba(34, 34, 34, 0.4);

    .company-info-item {
      margin: 12px 0;
      text-align: center;

      &:last-child {
        margin: 12px 0 0;
      }

      p {
        margin: 0;
        word-spacing: 0;
        letter-spacing: -0.4px;
      }
    }

    a.host-record-alink {
      text-align: center;
    }

    .policy-links {
      display: flex;
      flex-wrap: wrap;
      text-decoration: underline;

      .copyright-alink {
        flex-shrink: 0;
        margin: 0 30px 20px 0;
        color: var(--txt-color-lv1);

        &:last-child {
          margin: 0 0 20px 0;
        }
      }
    }
  }

  .safety {
    .safety-info-item {
      padding: 9px 0 9px 20px;
      font-size: 14px;
      line-height: 16px;
      color: rgba(34, 34, 34, 0.4);
      background-color: #eee;
      border-top: 1px solid #f5f5f5;
    }

    .safety-info-item:only-child {
      margin: 0 0 0 auto;
    }

    a.host-record-alink {
      margin: 0;
      padding: 0 5px;
      color: rgba(34, 34, 34, 0.4);
      text-decoration: underline;
      display: inline-block;
    }
  }
}

.footer.footer-hk {
  .module-item.company-info {
    .company-info-item {
      text-align: left;
    }
  }
}
</style>
