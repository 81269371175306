/* eslint-disable global-require */
/* eslint-disable max-len */
/**
 * home页配置
 */
export default {
  // 通用导航
  header: {
    banner: {
      bannerImg: 'hk-home-page-class',
      title: '學奧數，到學而思',
      description: '學而思在2003年以奧數起家，2018年來到香港，致力於成為全港最有趣、專業、負責的奧數學習中心。培養小朋友數學思維能力，衝刺各大奧數比賽。重視師資及培訓，賽課、備課、功底測試',
      mainBtnText: '了解我們的課程',
    },
  },

  // 核心优势
  coreAdvantages: {
    title: '為什麼選擇我們',
    advantages: [
      {
        name: '頂尖名師幫助學得透徹 專業課程體系激發數學思維',
        description: '資深老師授課，專業講解令複雜奧數題目更清晰易明，孩子能徹底掌握知識；國際頂尖數學教育家及本地專家的參與令積累17年的課程體系更完善，能有系統培養孩子數學思維。',
        picture: require('assets/images/home/hk/core-advantages-1.png'),
      },
      {
        name: '老師講解生動有趣',
        description: `老師風趣幽默，將對應教學內容動畫搭配趣味電子課件，燃起孩子學習熱情；
        科技推動教育，智能學習系統將課堂學習數據全面記錄，課堂更加零距離。`,
        picture: require('assets/images/home/hk/core-advantages-2.png'),
      },
      {
        name: '最盡責輔導老師全程跟進 課內課外貼心輔導',
        description: '輔導老師全程關注孩子上堂情況，及時解答疑問；課後一對一批改功課並提供額外輔導，無需家長操心，老師與家長保持密切聯絡，透過家長會及講座，共同成就孩子美好將來。',
        picture: require('assets/images/home/hk/core-advantages-3.png'),
      },
      {
        name: `開放課堂 鼓勵家長全程旁聽 
        首堂試聽 隨時退款`,
        description: `鼓勵家長坐在後排旁聽，更加了解課程內容和孩子上堂情況；
        首堂試聽，不滿意可隨時按比例退款。`,
        picture: require('assets/images/home/hk/core-advantages-4.png'),
      },
    ],
  },

  // 各项成绩，数字加载
  circleLoading: {
    title: '各項成績',
    // desc: '數學思維能力不斷提升，在各大奧數比賽斬獲佳績，升學結果出類拔萃！',
    listData: [
      {
        number: 97,
        desc: `逾97%同學入讀
        Band1中學`,
      },
      {
        number: 98.5,
        desc: `在讀學員奧數比賽
        獲獎率達98%`,
      },
      {
        number: 95,
        desc: `95%尖端班學員榮獲
        各大盃賽一等獎`,
      },
    ],
  },

  // 奥数课介绍
  classIntro: {
    title: `有趣、專業、
    負責的奧數課堂`,
    listData: [{
      title: '360度，全面匹配小朋友學習程度',
      description: '分層教學，授課老師隨時關注學員的即時掌握情況，及時調整課堂節奏和難度，提供更適合學員的課堂學習進度，提升學員課堂參與度、學習效果。',
      picture: require('assets/images/home/hk/class-intro-1.jpg'),
    }, {
      title: '課前關注度高',
      description: '提供課前預習學習資源（練習/影片），讓小朋友對課題有初步了解，提高小朋友上課進度一致性，提升小朋友學習自信心，激發學習興趣。',
      picture: require('assets/images/home/hk/class-intro-2.jpg'),
    }, {
      title: '高互動教學，課堂學習氣氛濃厚',
      description: '授課老師將故事貫穿課堂，並在課堂中設計大量高互動教學動作，調動小朋友上堂積極性，學而思的課堂主角是小朋友，每堂課都充斥著小朋友大聲回答問題的聲音。',
      picture: require('assets/images/home/hk/class-intro-3.jpg'),
    }, {
      title: '授予小朋友一生受益的能力是我們的教學目標',
      description: '好成績離不開好習慣的培養，學而思非常注重小朋友的學習培養。課堂歸根究底是學習知識，而學習的過程其實是知識不斷「輸入 – 轉化 – 輸出 – 留存」的過程。學而思老師會關注小朋友「閱讀」「筆記」「反思」「複習」「口述」等學習習慣。',
      picture: require('assets/images/home/hk/class-intro-4.jpg'),
    }, {
      title: '家校合作緊密，課後有溝通',
      description: '為了保證學習效果，授課老師會定期回訪家長，小朋友的學習情況、學習進度透明看德間。',
      picture: require('assets/images/home/hk/class-intro-5.jpg'),
    }],
  },

  // 用户评论
  parentsSay: {
    title: `父母的推薦，孩子的進步，
    是對我們的肯定`,
    content: [
      {
        name: '子薈媽咪',
        // status: 'Primary 4 Student',
        comment: '「學而思」最珍貴之處並不是只有明亮寬敞的校舍；也不是只有嚴格按照學生認知規律精心設計的教材；最真貴之處是在於有一眾優秀、專業、敬業又有愛心的老師。我的女兒自從入讀「學而思」以後，從一名害怕學數學的孩子變成愛上數學，真誠感謝「學而思」這個平台和各位辛苦付出的老師！謝謝Jeffrey Sir 和Allen Sir！',
      },
      {
        name: 'Nathan媽咪',
        // status: 'Primary 3 Student',
        comment: '學而思奥數，不死板，亦有很多互動，我小朋友很喜歡！疫情期間，由線下轉到線上學習，我小朋友依然專注投入，證明學而思課程有很大的吸引力！另外，老師們精心設計的課程，生動有趣的演繹，亦是誘發我小朋友積極學習的源頭！現在，我小朋友在學而思學習奥數已經有數個月，在這段時間，我不肯定他的數學成績會否突飛猛進，但可以肯定的是，他對奧數的熱愛是前所未見。',
      },
      {
        name: '儒心媽咪',
        // status: 'Parent of Primary 3 Intermediate student',
        comment: '儒心是一年班的學生，她自從參加了學而思的奧數課程之後，不但邏輯思維有進步，她的自信心都有提升。學而思的恆常班課程很有系統，老師講解清晰，令小朋友容易明白理解。特別欣賞學而思的網上教學，尤其在疫情期間，網上教學可維持小朋友學習。此外，學而思的網上學習系統十分完善，師生互動不比實體課遜色，小朋友十分喜歡上網上課堂。',
      },
      {
        name: '方凌媽咪',
        // status: 'Parent of Primary 4 Math Olympiad student',
        comment: '我多年以前在內地就認識學而思這個品牌了，所以當聽到學而思在香港開分校，第一時間就關注它的消息，兒子方凌成為學而思的第一批學員。感謝Allen Sir, Jeffrey Sir和Arthur Sir的悉心教導，兒子自從報讀課程以來，越來越喜歡上奧數，每週六都非常期待上課。在學而思由最起初的進階班 到現在的尖端班，得益於此，兒子在學校的數學成績一直靠前。感謝學而思，令兒子學而常思!',
      },
      {
        name: '逸蓁媽咪',
        // status: 'Parent of Primary 2 Intermediate student',
        comment: '我很榮幸兒子可以成為學而思的學員，小兒跳級參加學而思，初時還會顧慮他會否適應，但在小魚老師和miss June的悉心教導下，學習態度和數學思維都進步不少。雖然加入學而思只有半年，但感受到老師團隊真是很盡心教學和關心小朋友。小朋友亦很喜歡上課和做練習，除了提升奧數知識外，自信心也提升了不少。另外，學而思還有許多免費數學課程和講座，老師亦會犧牲自己休息時間去為小朋友補課，真心覺得學而思值得推薦。',
      },
      {
        name: '健仔媽咪',
        // status: 'Parent of Primary 5 Math Olympiad student',
        comment: `學而思課室寬敞，設備先進，就算現在肺炎病毒疫情嚴峻，但用學而思小班課上堂如同線下課一様，老師和學生溝通良好！完全沒有受到影響。
        學而思的教師團隊非常專業，教材內容很有系統，老師們熱忱無私地用心來教育小朋友。學而思首創家長「旁聽」制，讓家長清楚了解小朋友課堂時上課的情況和學習的內容，是香港首屈一指奧數的學校。`,
      },
      {
        name: '溫太',
        // status: 'Parent of Primary 2 Intermediate student',
        comment: '女女現在數學也有明顯進步，以前普通加減數也會猶豫很久才答到，現在心算好快就答對。對文字題也有十分大進步，會識睇tricky位，答案準確無誤。女女學校是用英文數學的，也沒有大問題，反而中文奧數題目更能訓練邏輯思考呢！',
      },
    ],
  },

  // 首页跳转免费课
  selectGrade: {
    title: '立即選擇適合你的課程，開啟高Fun奧數課',
    placeholder: '您孩子的年級',
    btnText: '查看課程',
  },

  getNotifiedConfig: {
    title: '加入學而思數學思維訓練群，一起培養小朋友思維能力！',
    description: '填寫右側申請表加入我們（已經入群的家長無需再提交申請）',
    form: {
      name: '小朋友中文全名',
      group: '申請加入的群組',
      contact: '手機號或微信號',
      email: '聯絡電郵',
    },
    notice: '我想接收學而思最新資訊，包括最新課程資料、學習資源、試題詳解等。',
    submit: '提交',
    success: '提交成功，感謝您對學而思的信任!',
  },
}
