<template>
  <div>
    <BottomPopup :show="countryCodePickerVisible" @right-icon-handler="onConfirm" @left-icon-handler="closePicker">
      <template slot="custom-body">
        <div class="phone-country-code-picker-container">
          <van-picker
            :columns="showCodeList"
            :default-index="defaultIndex"
            value-key="template"
            @change="changeHandler"
          />
        </div>
      </template>
    </BottomPopup>
  </div>
</template>
<script>
import { elementIndexOfArray } from 'common/utils.js';
import BottomPopup from '../BottomPopup/index.vue';

export default {
  components: { BottomPopup },
  props: {
    countryCode: {
      default: 'us',
      type: String,
    },

  },
  data() {
    return {
      countryCodePickerVisible: false,
      currentSelectedIndex: this.defaultIndex,
    }
  },
  computed: {
    defaultIndex() {
      return elementIndexOfArray('countryCode', this.countryCode, this.codeList);
    },
    showCodeList() {
      return this.codeList.map((codeItem) => ({
        countryName: codeItem.countryName,
        countryCode: codeItem.countryCode,
        value: codeItem.value,
        reg: codeItem.reg,
        template: `<span>+${codeItem.value}</span> <span>${codeItem.countryName}</span>`,
      }))
    },
    codeList() {
      return this.$store.state.phoneCodeList;
    },
  },
  methods: {
    openPicker() {
      this.currentSelectedIndex = this.defaultIndex;
      this.countryCodePickerVisible = true;
    },
    closePicker() {
      this.countryCodePickerVisible = false;
    },
    changeHandler(instance, selectedValue, selectedIndex) {
      this.currentSelectedIndex = selectedIndex;
    },
    onConfirm() {
      this.$emit('code-change', this.codeList[this.currentSelectedIndex]);
      this.closePicker();
    },
  },

}
</script>

<style lang="scss" scoped>
.phone-country-code-picker-container {
  padding: 0;
}
</style>
