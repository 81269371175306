/**
 * 各分校地址
 */
export default {
  url: [
    {
      locale: 'us',
      url: 'https://www.thethinkacademy.com',
      target: '_blank',
      name: {
        us: 'Think Academy United States',
        uk: 'Think Academy United States',
        sg: 'Think Academy United States',
        hk: '美國分校',
        tmc: 'Think Academy United States',
        au: 'Think Academy United States',
        my: 'Think Academy United States',
        ca: 'Think Academy United States',
        tc: 'Think Academy United States',
        tm: 'Think Academy United States',
        jp: 'Think Academy United States',
        kr: 'Think Academy United States',
        fr: 'Think Academy United States',
      },
    },
    {
      locale: 'uk',
      url: 'https://www.thinkacademy.uk',
      target: '_blank',
      name: {
        us: 'Think Academy United Kingdom',
        uk: 'Think Academy United Kingdom',
        sg: 'Think Academy United Kingdom',
        hk: '英國分校',
        tmc: 'Think Academy United Kingdom',
        au: 'Think Academy United Kingdom',
        my: 'Think Academy United Kingdom',
        ca: 'Think Academy United Kingdom',
        tc: 'Think Academy United Kingdom',
        tm: 'Think Academy United Kingdom',
        jp: 'Think Academy United Kingdom',
        kr: 'Think Academy United Kingdom',
        fr: 'Think Academy United Kingdom',
      },
    },
    {
      locale: 'sg',
      url: 'https://www.thinkacademy.sg',
      target: '_blank',
      name: {
        us: 'Think Academy Singapore',
        uk: 'Think Academy Singapore',
        sg: 'Think Academy Singapore',
        hk: '新加坡分校',
        tmc: 'Think Academy Singapore',
        au: 'Think Academy Singapore',
        my: 'Think Academy Singapore',
        ca: 'Think Academy Singapore',
        tc: 'Think Academy Singapore',
        tm: 'Think Academy Singapore',
        jp: 'Think Academy Singapore',
        kr: 'Think Academy Singapore',
        fr: 'Think Academy Singapore',
      },
    },
    {
      locale: 'hk',
      url: 'https://hk.thethinkacademy.com',
      target: '_blank',
      name: {
        us: 'Think Academy Hong Kong, China',
        uk: 'Think Academy Hong Kong, China',
        sg: 'Think Academy Hong Kong, China',
        hk: '香港分校',
        tmc: 'Think Academy Hong Kong, China',
        au: 'Think Academy Hong Kong, China',
        my: 'Think Academy Hong Kong, China',
        ca: 'Think Academy Hong Kong, China',
        tc: 'Think Academy Hong Kong, China',
        tm: 'Think Academy Hong Kong, China',
        jp: 'Think Academy Hong Kong, China',
        kr: 'Think Academy Hong Kong, China',
        fr: 'Think Academy Hong Kong, China',
      },
    },
    {
      locale: 'au',
      url: 'https://au.thethinkacademy.com',
      target: '_blank',
      name: {
        us: 'Think Academy Australia',
        uk: 'Think Academy Australia',
        sg: 'Think Academy Australia',
        hk: '澳大利亞分校',
        tmc: 'Think Academy Australia',
        au: 'Think Academy Australia',
        my: 'Think Academy Australia',
        ca: 'Think Academy Australia',
        tc: 'Think Academy Australia',
        tm: 'Think Academy Australia',
        jp: 'Think Academy Australia',
        kr: 'Think Academy Australia',
        fr: 'Think Academy Australia',
      },
    },
    {
      locale: 'my',
      url: 'https://thinkacademymy.com',
      target: '_blank',
      name: {
        us: 'Think Academy Malaysia',
        uk: 'Think Academy Malaysia',
        sg: 'Think Academy Malaysia',
        hk: '馬來西亞分校',
        tmc: 'Think Academy Malaysia',
        au: 'Think Academy Malaysia',
        my: 'Think Academy Malaysia',
        ca: 'Think Academy Malaysia',
        tc: 'Think Academy Malaysia',
        tm: 'Think Academy Malaysia',
        jp: 'Think Academy Malaysia',
        kr: 'Think Academy Malaysia',
        fr: 'Think Academy Malaysia',
      },
    },
    {
      locale: 'ca',
      url: 'https://www.thinkacademy.ca',
      target: '_blank',
      name: {
        us: 'Think Academy Canada',
        uk: 'Think Academy Canada',
        sg: 'Think Academy Canada',
        hk: '加拿大分校',
        tmc: 'Think Academy Canada',
        au: 'Think Academy Canada',
        my: 'Think Academy Canada',
        ca: 'Think Academy Canada',
        tc: 'Think Academy Canada',
        tm: 'Think Academy Canada',
        jp: 'Think Academy Canada',
        kr: 'Think Academy Canada',
        fr: 'Think Academy Canada',
      },
    },
    {
      locale: 'jp',
      url: 'https://jp.thethinkacademy.com',
      target: '_blank',
      name: {
        us: 'Think Academy Japan',
        uk: 'Think Academy Japan',
        sg: 'Think Academy Japan',
        hk: '日本分校',
        tmc: 'Think Academy Japan',
        au: 'Think Academy Japan',
        my: 'Think Academy Japan',
        ca: 'Think Academy Japan',
        tc: 'Think Academy Japan',
        tm: 'Think Academy Japan',
        jp: 'Think Academy Japan',
        kr: 'Think Academy Japan',
        fr: 'Think Academy Japan',
      },
    },
    {
      locale: 'kr',
      url: 'https://kr.thethinkacademy.com',
      target: '_blank',
      name: {
        us: 'Think Academy South Korea',
        uk: 'Think Academy South Korea',
        sg: 'Think Academy South Korea',
        hk: '韓國分校',
        tmc: 'Think Academy South Korea',
        au: 'Think Academy South Korea',
        my: 'Think Academy South Korea',
        ca: 'Think Academy South Korea',
        tc: 'Think Academy South Korea',
        tm: 'Think Academy South Korea',
        jp: 'Think Academy South Korea',
        kr: 'Think Academy South Korea',
        fr: 'Think Academy South Korea',
      },
    },
    {
      locale: 'fr',
      url: 'https://fr.thethinkacademy.com',
      target: '_blank',
      name: {
        us: 'Think Academy France',
        uk: 'Think Academy France',
        sg: 'Think Academy France',
        hk: '法國分校',
        tmc: 'Think Academy France',
        au: 'Think Academy France',
        my: 'Think Academy France',
        ca: 'Think Academy France',
        tc: 'Think Academy France',
        tm: 'Think Academy France',
        jp: 'Think Academy France',
        kr: 'Think Academy France',
        fr: 'Think Academy France',
      },
    },
    {
      locale: 'tmc',
      url: 'https://globalmathclub.com',
      target: '_blank',
      name: {
        us: 'Mathink Club',
        uk: 'Mathink Club',
        sg: 'Mathink Club',
        hk: '國際競賽分校',
        tmc: 'Mathink Club',
        au: 'Mathink Club',
        my: 'Mathink Club',
        ca: 'Mathink Club',
        tc: 'Mathink Club',
        tm: 'Mathink Club',
        jp: 'Mathink Club',
        kr: 'Mathink Club',
        fr: 'Mathink Club',
      },
    },
    {
      locale: 'bmus',
      url: 'https://www.bettermeedu.com/',
      target: '_blank',
      name: {
        us: 'BetterMe Math',
      },
    },
  ],
}
