/**
 * 编辑地址相关文案
 */
export default {
  showPostcode: true, // 是否显示邮编
  addressForm: {
    name: {
      label: 'Full Name',
      placeholder: 'Full Name',
    },
    phone: {
      label: 'Mobile Number',
      placeholder: 'Mobile Number',
    },
    postcode: {
      label: 'Postal Code',
      placeholder: 'Postal Code',
    },
    address1: {
      label: 'Address Line 1',
      placeholder: 'Block/House No. & Street Name',
    },
    address2: {
      label: 'Address Line 2',
      placeholder: 'Unit No. & Building Name',
    },
    city: {
      label: 'Town/City',
      placeholder: 'Town/City',
    },
    state: {
      label: 'State',
      placeholder: 'State',
    },
    suburb: {
      label: 'Suburb',
      placeholder: 'Suburb',
    },
    country: {
      label: 'Country',
      placeholder: 'Country',
    },
  },
  saveText: 'Save',
  billingAddress: 'Billing Address ',
  deliveryAddress: ' Delivery Address',
  billingAddressTip: ' Please provide your billing address for invoice',
  materialsTip: 'Add a new address to receive learning materials',
  editAddress: 'Edit Address',
  addAddress: 'Add Address',
  editText: 'Edit Address',
  noAddressTip: 'Please provide your address for delivery and invoice',
  default: 'Default',
  edit: 'Edit',
  delete: 'Delete',
  setAsDefault: 'Set as default',
  viewMore: 'View more addresses',
  viewLess: 'View less',
  addANewAddress: 'Add a new address',
  defaultAddress: 'Default address',
  deleteConfirmTip: 'Are you sure you want to delete this address？',
  confirm: 'Confirm',
  cancel: 'Cancel',
  noAddressList: 'You have not added an address yet',
  changeAddress: 'Change Address',
  curAddress: 'Current address',
  chooseANewAddress: 'Choose a new address',
  selectOtherAddress: 'Select other addresses',
}
