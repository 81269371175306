<template>
  <div class="unpaid-dialog">
    <ConfirmDialog ref="dd" :show.sync="show" img="box" :title="pageConfig.title" :show-main-btn="true"
                   :show-sub-btn="true" :sub-btn-text="$t('page.common.cancel')" :main-btn-text="pageConfig.see" @subBtnClick="packUp" @mainBtnClick="toUnpaid"
    >
      <template v-slot:content>
        <div class="contact-dialog-body">
          <p v-if="pageConfig.visit1&&pageConfig.visit2">
            {{ pageConfig.visit1 }}"<template v-if="pageConfig.portal!==''">
              <span> {{ pageConfig.portal }}</span> " - "
            </template><span> {{ $t('page.unpaidCourses.title') }}</span> " {{ pageConfig.visit2 }}
          </p>
          <p v-else>
            {{ pageConfig.visit1 }}
          </p>
          <p v-if="pageConfig.contact!=='' ">
            {{ $t('page.unpaidDialog.contact', [teacher]) }}
          </p>
        </div>
      </template>
    </ConfirmDialog>
  </div>
</template>

<script>
import ConfirmDialog from 'components/common/ConfirmDialog/index.vue';
// import gRecaptcha from '@thinkacademy/vitas-utils/gRecaptcha';

export default {
  components: { ConfirmDialog },
  data() {
    return {
      show: false,
      teacher: this.$t('teacher.teacher'),
    }
  },
  computed: {
    toBeContinueSku() {
      return this.$store.state.ucenter.unpaidList.toBeContinueSku
    },
    pageConfig() {
      if (this.toBeContinueSku) {
        return this.$t('page.paymentRemindDialog')
      }
      return this.$t('page.unpaidDialog')
    },
    saData() {
      return { notice_content: this.toBeContinueSku ? 1 : 2 }
    },
  },
  watch: {
  },
  // mounted() {
  //   const { verifyKey } = process.env;
  //   gRecaptcha.createScript.call(this, verifyKey)
  // },
  methods: {
    noticePopPv() {
      this.Sensors.track('notice_pop_pv', this.saData)
    },

    openDialog() {
      this.show = true;
      this.noticePopPv()
    },
    closeDialog() {
      this.show = false;
    },
    toUnpaid() {
      this.$router.push('/my-unpaid-courses/list')
      const data = this.saData
      data.button_name = this.pageConfig.see
      this.Sensors.track('notice_pop_click', data)
    },
    packUp() {
      this.$router.push('/')
      const data = this.saData
      data.button_name = this.$t('page.common.cancel')
      this.Sensors.track('notice_pop_click', data)
    },
  },
}
</script>

<style lang="scss" scoped>
/deep/.common-confirm-dialog-template .dialog-footer {
  .button {
    width: auto;
    white-space: nowrap;
    padding: px2vw(28) px2vw(38);
  }
}

.contact-dialog-body {
  padding: px2vw(48) 0;
  text-align: center;

  p {
    font-size: px2vw(28);
    font-weight: 500;
    color: rgba(162, 170, 184, 1);
    line-height: px2vw(32);
    &:nth-child(1) { margin-bottom: px2vw(44); }

    span {
      color: var(--txt-color-link);
      font-weight: 500;
    }
  }
}

</style>
<style>
.grecaptcha-badge { visibility: hidden; }
</style>
