<template>
  <div class="login-form-body">
    <div class="login-form-container">
      <van-form ref="loginForm" class="normal-grace-white-form" @submit="login">
        <EmailInput v-show="!isSend" v-model="emailData.email" :rules="loginRule.email" />
        <CodeInput v-show="isSend" ref="emailCode" v-model="emailData.captcha" :scene="scene" type="email" :email="emailData.email" :rules="loginRule.captcha" :cookie-name="cookieName" :before-send="codeBeforeSend" :send-success="codeSendSuccess" :send-error="codeSendError" />
      </van-form>
      <!-- 可以切换 -->
      <div v-if="canSwitchType">
        <div v-if="!isSend" class="button-container">
          <van-button type="primary" class="button button-normal primary-button font-semibold" :text="$t('login.login.loginForm.continueBtn')" :disabled="!isEmailContinue" :loading="sendLoading" @click="goSendCode('emailCode')" />
          <div class="divider">
            <span class="divider-line left" />
            <span class="divider-text">or</span>
            <span class="divider-line right" />
          </div>
          <van-button class="button button-normal common-button font-semibold" :text="$t('login.login.loginForm.switchPhone')" :disabled="sendLoading" @click="switchType('phone')" />
        </div>
        <div v-if="isSend" class="button-container">
          <van-button type="primary" class="button button-normal primary-button font-semibold" :text="$t('login.login.loginForm.submitBtnText')" :loading="isLoging" :disabled="!isLoginSubmitAvalible" @click="loginSubmit" />
        </div>
      </div>
      <!-- 不可以切换 -->
      <div v-else>
        <div v-show="!isSend" class="button-container">
          <div :class="['arrow-button', !isEmailContinue ? 'arrow-disabled': '']" @click="goSendCode('emailCode')">
            <div class="arrow-img" />
          </div>
        </div>
        <div v-show="isSend" class="button-container">
          <van-button type="primary" class="button button-normal dialog-button font-semibold" :text="$t('bindForm.submitBtn')" :loading="isLoging" :disabled="!isLoginSubmitAvalible" @click="loginSubmit" />
        </div>
        <!-- <div class="tip font-medium">
          {{ $t('bindForm.tip')+' '+contact }}
        </div> -->
      </div>
    </div>
  </div>
</template>
<script>
import EmailInput from 'components/login/newLoginForm/emailInput.vue';
import CodeInput from 'components/login/newLoginForm/codeInput.vue';
import { oneApi } from 'config/api';

export default {
  components: { EmailInput, CodeInput },
  props: {
    isLoging: { // 登陆按钮加载中
      type: Boolean,
      default: false,
    },
    canSwitchType: {
      type: Boolean,
      default: true,
    },
    cookieName: {
      type: String,
      default: 'login-phone-count',
    },
    scene: {
      type: Number,
      default: 1,
    },
    execute: {
      type: Boolean,
      default: false,
    },
    guide: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      isChecking: false, // 检测中
      contact: this.$t('common.contactPhone') !== '' ? this.$t('common.contactPhone') : this.$t('common.contactEmail'),
      isSend: false, // 是否切换到code页面
      sendLoading: false, // 按钮加载中状态
      emailData: {
        email: '',
        captcha: '',
      },
      loginRule: {
        email: [{
          required: true,
          message: this.$t('login.login.loginForm.email.rules.required'),
          trigger: 'onBlur',
          formatter: (value) => value.trim(),
        }, {
          pattern: /^([A-Za-z0-9_\-.])+@([A-Za-z0-9_\-.])+.([A-Za-z]{2,8})$/,
          message: this.$t('login.login.loginForm.email.rules.format'),
          trigger: 'onBlur',
          formatter: (value) => value.trim(),
        }],
        captcha: [{
          required: true,
          message: this.$t('login.login.loginForm.captcha.rules.required'),
          trigger: 'onBlur',
          formatter: (value) => value.trim(),
        },
        {
          pattern: /^\d{6}$/,
          message: this.$t('login.login.loginForm.captcha.rules.format'),
          trigger: 'onBlur',
          formatter: (value) => value.trim(),
        }],
      },
    }
  },
  computed: {
    isLoginSubmitAvalible() {
      return /^\d{6}$/.test(this.emailData.captcha);
    },
    isEmailContinue() {
      const isContinue = /^([A-Za-z0-9_\-.])+@([A-Za-z0-9_\-.])+.([A-Za-z]{2,8})$/.test(this.emailData.email)
      return isContinue
    },
  },
  methods: {
    init() {
      this.$refs.emailCode.init();
      this.emailData.captcha = '';
      this.isSend = false
    },
    /**
     * 触发登录按钮
     */
    loginSubmit() {
      this.$refs.loginForm.submit();
    },
    login() {
      this.$emit('submit', this.emailData)
    },
    // 切换表单
    switchType() {
      this.emailData.email = '';
      this.$refs.loginForm.resetValidation();
      this.$emit('switch')
    },
    // 触发发送验证码
    goSendCode(codInputName) {
      console.log('看看4')
      if (this.sendLoading || !this.isEmailContinue) {
        return undefined
      }
      if (this.execute) {
        return this.guide(this.emailData.email)
      }
      return this.$refs[codInputName].sendCodeHanlder()
    },
    // 验证码发送前
    codeBeforeSend(callback) {
      this.$refs.loginForm.validate('email').then(async () => {
        this.sendLoading = true;
        if (this.scene === 3 && !this.isChecking) {
          const data = {
            type: 2,
            contactInfo: this.emailData.email,
          }
          this.isChecking = true;
          console.log('校验手机号16');
          const res = await this.$axios.post(oneApi.contactVerify, data, { rewritePostBody: true })
          this.isChecking = false;
          if (Number(res.code) !== 0) {
            this.$Toast(res.msg);
            this.sendLoading = false;
            return;
          }
        }
        callback();
      });
    },
    // 发送成功
    codeSendSuccess() {
      this.sendLoading = false;
      this.isSend = true;
      // 修改dialog文案使用
      const valueCode = this.emailData.email
      this.$emit('titleChange', {
        isSend: this.isSend,
        valueCode,
      })
    },
    codeSendError() {
      this.sendLoading = false;
    },
  },
}
</script>
<style lang="scss" scoped>
.button-container {
  text-align: center;
}

.primary-button {
  width: px2vw(360);
  height: px2vw(96);
  background: #fff;
  border-radius: px2vw(48);
  font-size: px2vw(32);
  text-align: center;
  color: var(--txt-color-link);
  line-height: px2vw(96);
  padding: 0;
  margin-bottom: px2vw(16);

  &[disabled] {
    color: var(--txt-color-lv2);
    background: #f4f6fa;
  }
}

.common-button {
  width: px2vw(360);
  height: px2vw(96);
  border-radius: px2vw(48);
  border: px2vw(5) solid rgba(255, 255, 255, 0.8);
  font-size: px2vw(32);
  text-align: center;
  color: #fff;
  line-height: px2vw(96);
  padding: 0;
  background: transparent;

  &[disabled] {
    color: var(--txt-color-lv2);
    background: #f4f6fa;
  }
}

.dialog-button {
  width: px2vw(294);
  height: px2vw(96);
  text-align: center;
  background: var(--txt-color-link);
  border-radius: px2vw(48);
  color: #fff;
  padding: 0;
  font-size: px2vw(32);
  margin-bottom: px2vw(32);

  &[disabled] {
    color: var(--txt-color-lv2);
    background: #f4f6fa;
  }
}

.divider {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: px2vw(16);

  .divider-line {
    height: px2vw(2);
    width: px2vw(100);
    background-color: var(--txt-color-link3);
  }

  .left {
    margin-right: px2vw(14);
  }

  .right {
    margin-left: px2vw(14);
  }

  .divider-text {
    font-weight: 500;
    color: var(--txt-color-link3);
    font-size: px2vw(32);
  }
}

.arrow-button {
  width: px2vw(96);
  height: px2vw(96);
  background: var(--txt-color-link);
  border-radius: 50%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto px2vw(32);
  cursor: pointer;

  &:hover {
    background: var(--txt-color-link2);
  }

  .arrow-img {
    background: url('~assets/images/common/next-arrow.png') no-repeat;
    background-size: 100%;
    width: px2vw(40);
    height: px2vw(40);
  }
}

.arrow-disabled {
  opacity: 0.2;
}

.tip {
  font-size: px2vw(24);
  color: var(--txt-color-lv2);
  line-height: px2vw(28);
  text-align: center;
  margin-bottom: px2vw(40);
}
</style>
