<template>
  <div>
    <van-field v-model="inputValue" class="phone-input font-medium" name="phone" maxlength="16"
               :placeholder="$t('login.login.loginForm.phone.placeholder')" autocomplete="off" :clearable="true" :rules="rules" @blur="phoneBlurHandler"
    >
      <template slot="left-icon">
        <div class="phone-country-code-container" @click="openCountryCodePopup">
          <span class="phone-country-code-text">+{{ phoneAreaCode.value }}</span><i class="iconfont icon-arrow-bottom" />
        </div>
      </template>
    </van-field>
    <no-ssr>
      <CountryCodePopup ref="loginCountryCodePopup" :country-code="phoneAreaCode.countryCode" @code-change="countryCallingCodeChangeHandler" />
    </no-ssr>
  </div>
</template>
<script>
import { filterBlank } from 'common/utils.js';
import CountryCodePopup from 'components/common/CountryCodePopup';

export default {
  components: { CountryCodePopup },

  props: {
    value: {
      type: [String, Number],
      default: '',
    },
    rules: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    const { locale } = this.$store.state;
    return {
      locale,
      inputValue: '',
      phoneAreaCode: {},
      phoneCodeList: this.$store.state.phoneCodeList,
    }
  },
  watch: {
    value(val) {
      this.inputValue = val
    },
    inputValue(val) {
      this.$emit('input', val)
    },
    phoneAreaCode: {
      handler(val) {
        this.$emit('areaChange', val)
      },
      immediate: true,
      deep: true,
    },
  },
  mounted() {
    this.init();
  },
  methods: {
    async init() {
      if (!this.phoneCodeList.length) {
        await this.$store.dispatch('requeryBasicData', this.$axios)
        this.phoneCodeList = [...this.$store.state.phoneCodeList]
      }
      // 初始化手机区号对象
      const initPhoneAreaCode = this.$store.state.defaultPhoneCode || this.phoneCodeList[0]
      this.phoneAreaCode = initPhoneAreaCode;
    },
    // 手机输入框失焦处理
    phoneBlurHandler() {
      this.value = filterBlank(this.value)
    },
    // 打开手机区号选择器
    openCountryCodePopup() {
      this.$refs.loginCountryCodePopup.openPicker();
    },
    // 修改手机区号逻辑处理
    countryCallingCodeChangeHandler(args) {
      this.phoneAreaCode = args;
      this.$emit('areaChange', args)
    },
  },
}
</script>
<style lang="scss" scoped>
.phone-input {
  margin: 0;

  .phone-country-code-container {
    margin-right: px2vw(32);
    width: px2vw(124);

    .phone-country-code-text {
      display: inline-block;
      width: px2vw(80);
    }

    .icon-arrow-bottom {
      font-size: px2vw(20);
    }
  }
}
</style>
