<template>
  <div>
    <BottomPopup :show="isShow" :popup-title="$t('login.switchStudent.completeStudentProfile')" :show-left-icon="false">
      <template v-slot:right-icon>
        <div class="header-icon-btn-container right-icon-container" @click="closePicker">
          <i class="iconfont icon-close-new" />
        </div>
      </template>
      <template slot="custom-body">
        <div class="complete-student-info-dialog-body">
          <StudentInfoForm :student-data="studentInfo" :tip-text-top-of-btn="tipTextTopOfBtn" :submit-btn-text="submitBtnText" @save="saveHandler" />
        </div>
      </template>
    </BottomPopup>
  </div>
</template>
<script>
import bus from 'common/bus';
import { oneApi } from 'config/api';
import BottomPopup from 'components/common/BottomPopup/index.vue';
import StudentInfoForm from 'components/myAccount/StudentInfoForm/index.vue';

export default {
  components: { BottomPopup, StudentInfoForm },
  props: {
    triggerArgs: {
      default: () => ({}),
      type: Object,
    },
  },
  data() {
    const studentId = this.$route.query.studentId || '';
    return {
      studentId,
      isShow: false,
      tipTextTopOfBtn: this.$t('login.switchStudent.continueSignUpTip'),
      submitBtnText: this.$t('login.switchStudent.continueSignUp'),
      studentInfo: {
        nickName: '',
        firstName: '',
        lastName: '',
        gradeId: '',
        email: '',
      },
    }
  },
  methods: {
    async init() {
      this.studentInfo = Object.assign(this.studentInfo, this.$store.state.ucenter.user);
    },
    resetData() {
      this.studentInfo.nickName = '';
      this.studentInfo.firstName = '';
      this.studentInfo.lastName = '';
      this.studentInfo.gradeId = '';
      this.studentInfo.email = '';
    },
    saveHandler(newData) {
      this.completeStuInfoClick()
      this.studentInfo = Object.assign(this.studentInfo, newData);
      this.save();
    },
    async save() {
      const params = {
        nickName: this.studentInfo.nickName.trim(),
        firstName: this.studentInfo.firstName.trim(),
        lastName: this.studentInfo.lastName.trim(),
        gradeId: this.studentInfo.gradeId,
      }
      const res = await this.$axios.post(oneApi.modifyUserInfo, params, { rewritePostBody: true, uid: this.studentId || (this.studentInfo && this.studentInfo.uid) || '' });

      if (Number(res.code) !== 0) {
        this.$Toast(res.msg || 'Failed to complete student information, please try again')
        return;
      }

      this.$store.commit('ucenter/SET_USER', this.studentInfo);

      // 异步更新用户信息
      this.$store.dispatch('ucenter/queryUserBasicInfo', { $axios: this.$axios, userData: { fillInAttention: false } });
      this.saveSuccessHandler();
    },
    async newEmailOrPhone(obj) {
      let data = {}
      let res;
      // let data2 = null

      // if (obj.phone) {
      //   data2 = {
      //     type: 0,
      //     contactInfo: obj.phone,
      //     countryCallingCode: obj.phoneAreaCode.value,
      //   }
      // } else {
      //   data2 = {
      //     type: 2,
      //     contactInfo: obj,
      //   }
      // }
      console.log('校验手机号15');
      // const res2 = await this.$axios.post(oneApi.contactVerify, data2, { rewritePostBody: true })

      // if (Number(res2.code) !== 0) {
      //   this.$Toast(res2.msg)
      //   return;
      // }

      if (obj.phone) {
        data = {
          attentionPhone: obj.phone,
          attentionCountryCode: obj.phoneAreaCode.value,
        }
        res = await this.$axios.post(oneApi.bindEmail2, data, { rewritePostBody: true, uid: this.studentId })
      } else {
        data = {
          attentionEmail: obj,
        }
        res = await this.$axios.post(oneApi.bindEmail2, data, { rewritePostBody: true, uid: this.studentId })
      }
      // 触发后续购课流程
      if (Number(res.code) !== 0) {
        this.$Toast(res.msg)
        return;
      }
      this.$store.dispatch('ucenter/queryUserBasicInfo', { $axios: this.$axios, userData: { fillInAttention: false } }).then(() => {
        this.saveSuccessHandler()
      });
      this.$store.dispatch('bindDialog/close')
    },
    async  saveSuccessHandler() {
      // 关闭弹窗
      this.closePicker();

      // 成功后跳转路径
      if (this.triggerArgs) {
        if (this.triggerArgs.triggerSrc === 'REGISTRATION_COURSE') {
          // 触发后续购课流程
          bus.$emit('courses.detail.registrationCourses', false)
        }
        if (this.triggerArgs.triggerSrc === 'UNPAID_COURSE') {
          const skuId = this.triggerArgs.skuId || '';
          const res = await this.$axios.post(oneApi.toBePaidCheck, { skuId }, { rewritePostBody: true, uid: this.studentId || (this.studentInfo && this.studentInfo.uid) || '' })
          if (Number(res.code) !== 0) {
            this.$Toast(res.msg);
            return;
          }
          const resData = res.data.helper || {};
          if (resData.status === 1) {
            this.$store.dispatch('bindDialog/setDialogData', {
              visible: true,
              type: 'email',
              execute: true,
              guide: this.newEmailOrPhone,
            })
            return
          }
          if (resData.status === 2) {
            this.$store.dispatch('bindDialog/setDialogData', {
              visible: true,
              type: 'phone',
              execute: true,
              guide: this.newEmailOrPhone,
            })
            return
          }
          // 需要完善个人信息
          if (resData.status === 3) {
            bus.$emit('complete-stu-info', { triggerSrc: 'UNPAID_COURSE' });
            return
          }
          // 续费课程，跳转至创建订单页面
          if (skuId) this.$router.push(`/order/create/${skuId}?fromPage=unpaid-courses-list${this.triggerArgs.studentId ? `&studentId=${this.triggerArgs.studentId}` : ''}`);
        }
      }
    },
    async openPicker(params) {
      this.resetData();
      // await this.init();
      this.studentInfo = Object.assign(this.studentInfo, (params && params.studentInfo) || {});
      this.isShow = true;
      this.completeStuInfoShow()
    },
    closePicker() {
      this.isShow = false;
    },
    // 神策埋点-完善个人信息弹窗曝光
    completeStuInfoShow() {
      this.Sensors.track('student_profile_pv')
    },
    completeStuInfoClick() {
      this.Sensors.track('student_profile_click')
    },
  },

}
</script>

<style lang="scss" scoped>
.right-icon-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: px2vw(64);
  height: px2vw(64);
  text-align: center;
  border-radius: 50%;
  color: var(--txt-color-lv2);
  background: #f4f6fa;

  .iconfont {
    font-size: px2vw(40);
  }
}

.complete-student-info-dialog-body {
  padding: px2vw(40) 0 px2vw(48);
  height: px2vw(1040);
  overflow: auto;
}
</style>
