/**
 * 提供APP与H5通信插件
 */

import Cookies from 'js-cookie';
import bus from 'common/bus';
import { printLog } from 'common/utils';
// { route }
export default ({ store, redirect }) => {
  // 忽略非APP嵌入页面
  if (process.server) return;
  if (!window.JsInjectionActivity) return;

  // APP调用H5通信全局方法
  // 调用方法:
  // GLOBAL_APP_HUB.updateGradeId(gradeId);
  const GLOBAL_APP_HUB = {
    /**
     * 页面跳转
     * options.url 页面地址
     */
    pageJump(options) {
      const { url } = options || {}
      if (!url) {
        return
      }
      redirect(url)
    },
    /**
     * 更新年级
     * @string {年级ID} gradeId
     */
    updateGradeId(gradeId) {
      // 产品暂时无更新GrideId功能

      // 调试日志
      const jsonData = JSON.stringify({
        methodName: 'window.GLOBAL_APP_HUB.updateGradeId',
        params: {
          gradeId,
        },
      });
      printLog('Client Call H5 LOG', jsonData);
    },

    /**
     * 登录成功回调
     */
    loginSuccess() {
      // 暂时不使用
      window.location.reload();
      // 调试日志
      const jsonData = JSON.stringify({
        methodName: 'window.GLOBAL_APP_HUB.loginSuccess',
      });
      printLog('Client Call H5 LOG', jsonData);
    },

    /**
     * 退出成功回调
     */
    logoutSuccess() {
      // 清除cookie
      Cookies.remove('_app_token', '');
      store.commit('ucenter/SET_IS_LOGIN', false);

      // 调试日志
      const jsonData = JSON.stringify({
        methodName: 'window.GLOBAL_APP_HUB.logoutSuccess',
      });
      printLog('Client Call H5 LOG', jsonData);
    },

    /**
     * 苹果内购支付成功回调
     */
    iOSPaySuccess() {
      const source = window.localStorage.getItem('orderSuccessSourceFlag');
      if (source === 'order') {
        window.location.href = `${window.location.origin}/app/order/list`;
      } else if (source === 'course') {
        window.location.href = `${window.location.origin}/app`;
      }
      // 调试日志
      const jsonData = JSON.stringify({
        methodName: 'window.GLOBAL_APP_HUB.iOSPaySuccess',
      });
      printLog('Client Call H5 LOG', jsonData);
    },
  }

  // H5调用返回到指定的原生页面
  bus.$on('app.hub.goTo', (params) => {
    console.log(params)
    const jsonData = JSON.stringify({
      className: 'HWTouchStore',
      methodName: 'jumpCourseList',
      params: {
        courseType: params.courseType, // recorded：录播课Tab 、live：直播课Tab
      },
    });
    printLog('H5 Call Client LOG', jsonData);
    window.JsInjectionActivity.jsCallClient(jsonData)
  })

  // H5调用跳转到商城首页
  bus.$on('app.hub.goToShop', () => {
    const jsonData = JSON.stringify({
      className: 'HWTouchStore',
      methodName: 'jumpStore',
      params: {},
    });
    printLog('H5 Call Client LOG', jsonData);
    window.JsInjectionActivity.jsCallClient(jsonData)
  })
  // H5调用APP登录方法
  // APP登录成功会将token写入cookie中，cookie为: _app_token
  // 使用方法:
  // bus.$emit('app.hub.login')
  bus.$on('app.hub.login', () => {
    const jsonData = JSON.stringify({
      className: 'ThinkAcademyApp',
      methodName: 'login',
    });
    printLog('H5 Call Client LOG', jsonData);
    window.JsInjectionActivity.jsCallClient(jsonData);
  });

  // H5调用APP返回
  bus.$on('app.hub.goback', () => {
    const jsonData = JSON.stringify({
      className: 'ThinkAcademyApp',
      methodName: 'goBack',
    });
    printLog('H5 Call Client LOG', jsonData);
    window.JsInjectionActivity.jsCallClient(jsonData);
  });

  // 购课成功后通知原生App购课成功
  bus.$on('app.hub.buyCourseSuccess', () => {
    const jsonData = JSON.stringify({
      className: 'ThinkAcademyApp',
      methodName: 'buyCourseSuccess',
    });
    printLog('H5 Call Client LOG', jsonData);
    window.JsInjectionActivity.jsCallClient(jsonData);
  });

  /**
   * IOS苹果内购
   */
  bus.$on('app.hub.iOSPay', (data) => {
    const jsonData = JSON.stringify({
      className: 'ThinkAcademyApp',
      methodName: 'IAPLoad:',
      params: {
        orderid: data.orderId,
        productid: data.courseId,
      },
    });
    console.log(jsonData)
    printLog('H5 Call Client LOG', jsonData);
    window.JsInjectionActivity.jsCallClient(jsonData);
  });

  // 初始访问/app页面时，将GLOBAL_APP_HUB共享到全局变量
  window.GLOBAL_APP_HUB = GLOBAL_APP_HUB;
}
