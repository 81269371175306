<template>
  <van-popup
    v-model="show"
    class="common-confirm-dialog-wrapper"
    get-container="body"
    :close-on-click-overlay="false"
    :round="true"
  >
    <div class="common-confirm-dialog-template">
      <slot name="main">
        <div class="dialog-header">
          <div v-if="showClose" class="close-icon-container" @click="closeHandler">
            <i class="iconfont icon-close" />
          </div>
        </div>
        <div class="dialog-body-container">
          <div v-if="img" :class="`img ${img}`" />
          <div v-if="title" class="title font-semibold" :class="{'custom-title': customTitle}">
            {{ title }}
          </div>
          <div class="dialog-content-container">
            <slot name="content">
              <div v-if="content" class="content font-medium" v-html="content" />
              <slot name="content-bottom" />
            </slot>
          </div>
        </div>
        <div class="dialog-footer" :class="{'custom-footer': customFooter}">
          <van-button v-if="subBtnText || showSubBtn" class="button button-normal button-default" @click="subBtnClickHandler">
            {{ subBtnText }}
          </van-button>
          <van-button v-if="mainBtnText || showMainBtn" class="button button-normal" @click="mainBtnClickHandler">
            {{ mainBtnText }}
          </van-button>
        </div>
        <slot />
      </slot>
    </div>
  </van-popup>
</template>

<script>
export default {
  props: {
    show: {
      default: false,
      type: Boolean,

    },
    title: {
      default: '',
      type: String,
    },
    img: {
      default: '',
      type: String,
    },
    content: {
      default: '',
      type: String,
    },
    mainBtnText: {
      default: '',
      type: String,
    },
    showMainBtn: {
      default: true,
      type: Boolean,
    },
    subBtnText: {
      default: '',
      type: String,
    },
    showSubBtn: {
      default: false,
      type: Boolean,
    },
    showClose: {
      default: false,
      type: Boolean,
    },
    // 自定义标题样式
    customTitle: {
      default: false,
      type: Boolean,
    },
    customFooter: {
      default: false,
      type: Boolean,
    },
  },
  methods: {
    mainBtnClickHandler() {
      this.$emit('mainBtnClick');
      this.closeHandler();
    },
    subBtnClickHandler() {
      this.$emit('subBtnClick');
      this.closeHandler();
    },
    closeHandler() {
      this.$emit('update:show', false);
    },
  },
}
</script>

<style lang="scss" scoped>
.common-confirm-dialog-wrapper.van-popup--round {
  border-radius: px2vw(32);
  overflow: visible;
}

.common-confirm-dialog-template {
  position: relative;
  padding: px2vw(68) px2vw(32) px2vw(32);
  width: px2vw(686);

  .dialog-header {
    margin-bottom: px2vw(32);

    .close-icon-container {
      position: absolute;
      top: px2vw(32);
      right: px2vw(32);
      padding: px2vw(8) 0;
      width: px2vw(64);
      height: px2vw(64);
      text-align: center;
      background: #f3f6fa;
      border-radius: 50%;
      z-index: 999;
      cursor: pointer;

      .iconfont {
        color: var(--txt-color-lv2);
        font-size: px2vw(28);
        font-weight: bold;
        line-height: px2vw(48);
      }

      &:hover .iconfont {
        color: var(--txt-color-lv1);
        background: #f6f6f6;
      }
    }
  }

  .dialog-body-container {
    margin-bottom: px2vw(48);

    .img {
      background-size: 100% 100%;
      margin: 0 auto;
      width: px2vw(300);
      height: px2vw(300);

      &.box {
        background: url('~assets/images/error/unpaid_box.png') no-repeat;
        background-size: 100% 100%;
        margin: px2vw(-106) auto px2vw(20);
        width: px2vw(224);
        height: px2vw(224);
      }
    }

    .title {
      margin-bottom: px2vw(20);
      width: px2vw(622);
      text-align: center;
      font-size: px2vw(36);
      line-height: px2vw(42);
      color: var(--txt-color-lv1);
    }

    .title.custom-title {
      margin: 10px 0 0 0;
    }

    .dialog-content-container {
      min-height: px2vw(100);
      word-break: normal;
    }

    .content {
      width: px2vw(622);
      text-align: center;
      font-size: px2vw(28);
      line-height: px2vw(32);
      color: var(--txt-color-lv2);
    }
  }

  .dialog-footer {
    display: flex;
    justify-content: center;

    .button {
      width: 100%;
      margin-right: px2vw(32);
      text-align: center;
      font-size: px2vw(32);
      line-height: px2vw(38);

      &:last-child {
        margin-right: 0;
      }
    }
  }
}
</style>
