const description = ` <p>EdTech-Powered Live  Online Learning Platform</p>
<ul>
  <li>Get access to interactive virtual classrooms</li>

  <li>Answer questions and enjoy learning activities</li>

  <li>Win rewards for even more fun</li>
</ul>
`;
export default {
  title: 'Think Academy Student',
  requirement: {
    ios: 'Only supports iOS 10.0 and above.<br/>iPhone5/5c/5s, iPhone6/Plus, iPhone6s/Plus, iPhone SE are NOT supported for an ultimate class experience',
    android: 'Only supports Android 6.0 and above.<br/>Minimum configurations (Tablet):<br/>2.0 GHz processor speed, 4GB RAM',
  },
  description: `${description}`,
  tip: 'For desktop users, please download the software from the Think Academy website on your laptop.',
  download: 'Download',
  guideNotice: 'Please open this link in browser to download Think Academy Parent.',
}
