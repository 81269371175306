/* eslint-disable max-len */
// 登录流程相关页面配置
export default {
  login: {
    DontHaveAccoun: "沒有帳戶？",
    ItSeemsHaven: "看起來您尚未註冊。",
    SignUpNow: '現在註冊',
    SignInNow: '現在登入',
    ThisEmailAlready: '此電子郵件已被佔用。',
    ThisPhoneAlready: '此電話號碼已被佔用。', 
    AlreadyHave: '已有帳戶？',
    CreateAccount: '创建帳戶',
    FREEEvaluation: '登入帳戶预约免費評估',
    CreateANewAccount: '创建新帳戶',
    CreateAnAccountTo: '创建帳戶预约免費評估',
    ContinueWith: '繼續使用',
    policiesTip: '請確認勾選協議後再登入',
    title: '登入或注册',
    confirmTitleForPhone: '請驗證您的手機號',
    confirmTitleForEmail: '請驗證您的郵箱',
    subTitle: '還沒有帳號？您可以輸入您的手機號注册一個帳號',
    subTitleForEmail: '還沒有帳號？請輸入您的郵箱註冊一個',
    confirmSubTitleForPhone: '請輸入我們發送到您手機號：',
    confirmSubTitleForEmail: '請輸入我們發送給您郵箱：',
    skipBtn: '跳過',
    firstLogin: '登錄或創建帳戶',
    loginArgain: '歡迎回來',
    loginForm: {
      phone: {
        label: '手機號',
        continueBtn: '使用電話繼續',
        switchBtn: '使用郵箱繼續',
        placeholder: '手機號',
        rules: {
          required: '此處為必填項',
          format: '請輸入有效手機號',
        },
        verifyMainTitle: '驗證您的手機號',
        verifySubTitle: '輸入短信驗證碼:',
      },
      captcha: {
        placeholder: '驗證碼',
        rules: {
          required: '此處為必填項',
          format: '輸入的驗證碼不正確',
        },
      },
      email: {
        label: '郵箱地址',
        continueBtn: '使用郵箱繼續',
        switchBtn: '使用電話繼續',
        placeholder: '郵箱地址',
        rules: {
          required: '此處為必填項',
          format: '輸入的邮箱不正確',
        },
        verifyMainTitle: '驗證您的郵箱地址',
        verifySubTitle: '我們已向您的收件箱發送了電子郵件驗證碼:',
      },
      changeTipText: 'or',
      continueBtn: '下一步',
      submitBtnText: '登入',
      switchEmail: '用郵箱登入',
      switchPhone: '用手機號登入',
    },
    otpSendBtn: {
      normal: '發送',
      reResend: '重發',
    },
    legalTip: '點擊選擇框以便注冊和登入',
    legalCheck: '我已閱讀並同意<a class="link-text" href="/terms-of-use" target="_blank">用戶協議</a>、<a class="link-text" href="/privacy-policy" target="_blank">隱私協議</a>、<a class="link-text" href="/child-privacy" target="_blank">兒童隱私協議</a>',
    loginFailed: '登錄失敗',
    loginSuccess: '登錄成功',
  },
  switchStudent: {
    choose: '選擇要報名的學生',
    add: '添加新賬號',
    addStudent: '添加學生',
    currentStudent: '當前學生',
    continueSignUp: '繼續報名',
    phoneNumTip: '您用於登錄的({0}){1}手機號碼已與以下學生關聯:',
    dialogTip: '您可以為家庭組內任一成員購課',
    addTip: ['為上面未列出的其他孩子註冊?請', '單擊', '此處添加新的學生帳戶以報名不同的課程', '點擊“繼續報名”後即可完成學生資料'],
    completeStudentProfile: '完善學員信息',
    continueSignUpTip: '學生信息可以幫助教師了解您的孩子。填寫完成後，可單擊“繼續報名”去付款。',
  },
};
