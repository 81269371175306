import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _2894318d = () => interopDefault(import('../../../src/client/pages/touch/1-to-1-assessment/index.vue' /* webpackChunkName: "pages/touch/1-to-1-assessment/index" */))
const _09c27a4a = () => interopDefault(import('../../../src/client/pages/touch/about-think-academy/index.vue' /* webpackChunkName: "pages/touch/about-think-academy/index" */))
const _022efb9f = () => interopDefault(import('../../../src/client/pages/touch/about-xes/index.vue' /* webpackChunkName: "pages/touch/about-xes/index" */))
const _0cb9d2e4 = () => interopDefault(import('../../../src/client/pages/touch/aboutus/index.vue' /* webpackChunkName: "pages/touch/aboutus/index" */))
const _3a080dfc = () => interopDefault(import('../../../src/client/pages/touch/acceptable-use-policy/index.vue' /* webpackChunkName: "pages/touch/acceptable-use-policy/index" */))
const _64f12a08 = () => interopDefault(import('../../../src/client/pages/touch/activated-page/index.vue' /* webpackChunkName: "pages/touch/activated-page/index" */))
const _70a22e62 = () => interopDefault(import('../../../src/client/pages/touch/appointmentDiagnosis/index.vue' /* webpackChunkName: "pages/touch/appointmentDiagnosis/index" */))
const _6a2dd3ff = () => interopDefault(import('../../../src/client/pages/touch/assessment/index.vue' /* webpackChunkName: "pages/touch/assessment/index" */))
const _02182ac9 = () => interopDefault(import('../../../src/client/pages/touch/bind-email/index.vue' /* webpackChunkName: "pages/touch/bind-email/index" */))
const _47338abb = () => interopDefault(import('../../../src/client/pages/touch/career/index.vue' /* webpackChunkName: "pages/touch/career/index" */))
const _19d56b60 = () => interopDefault(import('../../../src/client/pages/touch/child-privacy/index.vue' /* webpackChunkName: "pages/touch/child-privacy/index" */))
const _f297ae26 = () => interopDefault(import('../../../src/client/pages/touch/cookies-policy/index.vue' /* webpackChunkName: "pages/touch/cookies-policy/index" */))
const _64d7b6ec = () => interopDefault(import('../../../src/client/pages/touch/course-material-fee-policy/index.vue' /* webpackChunkName: "pages/touch/course-material-fee-policy/index" */))
const _2e639771 = () => interopDefault(import('../../../src/client/pages/touch/courses/index.vue' /* webpackChunkName: "pages/touch/courses/index" */))
const _2f341283 = () => interopDefault(import('../../../src/client/pages/touch/customer-service/index.vue' /* webpackChunkName: "pages/touch/customer-service/index" */))
const _aa9bb276 = () => interopDefault(import('../../../src/client/pages/touch/download/index.vue' /* webpackChunkName: "pages/touch/download/index" */))
const _104ee04f = () => interopDefault(import('../../../src/client/pages/touch/faq/index.vue' /* webpackChunkName: "pages/touch/faq/index" */))
const _dd481948 = () => interopDefault(import('../../../src/client/pages/touch/home/index.vue' /* webpackChunkName: "pages/touch/home/index" */))
const _ec7cfd78 = () => interopDefault(import('../../../src/client/pages/touch/internet-safety-tips/index.vue' /* webpackChunkName: "pages/touch/internet-safety-tips/index" */))
const _0312f738 = () => interopDefault(import('../../../src/client/pages/touch/level-test/index.vue' /* webpackChunkName: "pages/touch/level-test/index" */))
const _3f2cc417 = () => interopDefault(import('../../../src/client/pages/touch/locations/index.vue' /* webpackChunkName: "pages/touch/locations/index" */))
const _a6036d3c = () => interopDefault(import('../../../src/client/pages/touch/login/index.vue' /* webpackChunkName: "pages/touch/login/index" */))
const _0a4e57c9 = () => interopDefault(import('../../../src/client/pages/touch/my-account/index.vue' /* webpackChunkName: "pages/touch/my-account/index" */))
const _a24a1c4c = () => interopDefault(import('../../../src/client/pages/touch/my-class-transfer/index.vue' /* webpackChunkName: "pages/touch/my-class-transfer/index" */))
const _8efffa6c = () => interopDefault(import('../../../src/client/pages/touch/pdf-viewer/index.vue' /* webpackChunkName: "pages/touch/pdf-viewer/index" */))
const _accbfed4 = () => interopDefault(import('../../../src/client/pages/touch/pricing-policy/index.vue' /* webpackChunkName: "pages/touch/pricing-policy/index" */))
const _01c1d33a = () => interopDefault(import('../../../src/client/pages/touch/privacy-notice/index.vue' /* webpackChunkName: "pages/touch/privacy-notice/index" */))
const _57d53914 = () => interopDefault(import('../../../src/client/pages/touch/privacy-policy/index.vue' /* webpackChunkName: "pages/touch/privacy-policy/index" */))
const _4f69c0c0 = () => interopDefault(import('../../../src/client/pages/touch/refund-policy/index.vue' /* webpackChunkName: "pages/touch/refund-policy/index" */))
const _c956c3c4 = () => interopDefault(import('../../../src/client/pages/touch/resources/index.vue' /* webpackChunkName: "pages/touch/resources/index" */))
const _6d7f3d2e = () => interopDefault(import('../../../src/client/pages/touch/safeguarding-policy/index.vue' /* webpackChunkName: "pages/touch/safeguarding-policy/index" */))
const _c820c544 = () => interopDefault(import('../../../src/client/pages/touch/shopping-cart/index.vue' /* webpackChunkName: "pages/touch/shopping-cart/index" */))
const _5c1f7b44 = () => interopDefault(import('../../../src/client/pages/touch/short-term-course/index.vue' /* webpackChunkName: "pages/touch/short-term-course/index" */))
const _54d74d0a = () => interopDefault(import('../../../src/client/pages/touch/student-portal/index.vue' /* webpackChunkName: "pages/touch/student-portal/index" */))
const _3189eae4 = () => interopDefault(import('../../../src/client/pages/touch/subscription-manage/index.vue' /* webpackChunkName: "pages/touch/subscription-manage/index" */))
const _05f4ca16 = () => interopDefault(import('../../../src/client/pages/touch/success-page/index.vue' /* webpackChunkName: "pages/touch/success-page/index" */))
const _b760d9ca = () => interopDefault(import('../../../src/client/pages/touch/teacher/index.vue' /* webpackChunkName: "pages/touch/teacher/index" */))
const _1c57f064 = () => interopDefault(import('../../../src/client/pages/touch/teachers/index.vue' /* webpackChunkName: "pages/touch/teachers/index" */))
const _638b949e = () => interopDefault(import('../../../src/client/pages/touch/terms-and-conditions/index.vue' /* webpackChunkName: "pages/touch/terms-and-conditions/index" */))
const _6cade614 = () => interopDefault(import('../../../src/client/pages/touch/terms-of-use/index.vue' /* webpackChunkName: "pages/touch/terms-of-use/index" */))
const _302f85e5 = () => interopDefault(import('../../../src/client/pages/touch/uk-tax-strategy/index.vue' /* webpackChunkName: "pages/touch/uk-tax-strategy/index" */))
const _35e7c976 = () => interopDefault(import('../../../src/client/pages/touch/user-agreement/index.vue' /* webpackChunkName: "pages/touch/user-agreement/index" */))
const _d99a8aea = () => interopDefault(import('../../../src/client/pages/touch/app/brand-introduction/index.vue' /* webpackChunkName: "pages/touch/app/brand-introduction/index" */))
const _6a94829b = () => interopDefault(import('../../../src/client/pages/touch/app/debug.vue' /* webpackChunkName: "pages/touch/app/debug" */))
const _2b1591e1 = () => interopDefault(import('../../../src/client/pages/touch/app/faq/index.vue' /* webpackChunkName: "pages/touch/app/faq/index" */))
const _1bf63842 = () => interopDefault(import('../../../src/client/pages/touch/app/privacy-policy/index.vue' /* webpackChunkName: "pages/touch/app/privacy-policy/index" */))
const _29c0d8c2 = () => interopDefault(import('../../../src/client/pages/touch/app/terms-of-use/index.vue' /* webpackChunkName: "pages/touch/app/terms-of-use/index" */))
const _7e889396 = () => interopDefault(import('../../../src/client/pages/touch/careers/job-listings/index.vue' /* webpackChunkName: "pages/touch/careers/job-listings/index" */))
const _95bafcdc = () => interopDefault(import('../../../src/client/pages/touch/courses/list/index.vue' /* webpackChunkName: "pages/touch/courses/list/index" */))
const _1d7bf613 = () => interopDefault(import('../../../src/client/pages/touch/customer-service/problem/index.vue' /* webpackChunkName: "pages/touch/customer-service/problem/index" */))
const _dcc9af32 = () => interopDefault(import('../../../src/client/pages/touch/home/config.js' /* webpackChunkName: "pages/touch/home/config" */))
const _deca276e = () => interopDefault(import('../../../src/client/pages/touch/my-account/add-student/index.vue' /* webpackChunkName: "pages/touch/my-account/add-student/index" */))
const _e06ccb74 = () => interopDefault(import('../../../src/client/pages/touch/my-class-transfer/order-acknowledgement/index.vue' /* webpackChunkName: "pages/touch/my-class-transfer/order-acknowledgement/index" */))
const _8f6b9824 = () => interopDefault(import('../../../src/client/pages/touch/my-coupons/history/index.vue' /* webpackChunkName: "pages/touch/my-coupons/history/index" */))
const _325f795a = () => interopDefault(import('../../../src/client/pages/touch/my-coupons/list/index.vue' /* webpackChunkName: "pages/touch/my-coupons/list/index" */))
const _0a02f74f = () => interopDefault(import('../../../src/client/pages/touch/my-courses/list/index.vue' /* webpackChunkName: "pages/touch/my-courses/list/index" */))
const _0dbe52ec = () => interopDefault(import('../../../src/client/pages/touch/my-subscription/list/index.vue' /* webpackChunkName: "pages/touch/my-subscription/list/index" */))
const _67e3f6ce = () => interopDefault(import('../../../src/client/pages/touch/my-unpaid-courses/list/index.vue' /* webpackChunkName: "pages/touch/my-unpaid-courses/list/index" */))
const _5d77c722 = () => interopDefault(import('../../../src/client/pages/touch/my-wish/list/index.vue' /* webpackChunkName: "pages/touch/my-wish/list/index" */))
const _019d5fbc = () => interopDefault(import('../../../src/client/pages/touch/order/list/index.vue' /* webpackChunkName: "pages/touch/order/list/index" */))
const _572682ec = () => interopDefault(import('../../../src/client/pages/touch/shopping-cart/OfferDetails.vue' /* webpackChunkName: "pages/touch/shopping-cart/OfferDetails" */))
const _c99129f4 = () => interopDefault(import('../../../src/client/pages/touch/student-portal/student-edit/index.vue' /* webpackChunkName: "pages/touch/student-portal/student-edit/index" */))
const _d1d4cb74 = () => interopDefault(import('../../../src/client/pages/touch/test/recommended-courses/index.vue' /* webpackChunkName: "pages/touch/test/recommended-courses/index" */))
const _6ac72b5e = () => interopDefault(import('../../../src/client/pages/touch/test/test-results/index.vue' /* webpackChunkName: "pages/touch/test/test-results/index" */))
const _312e4b64 = () => interopDefault(import('../../../src/client/pages/touch/courses/recorded-detail/syllabus-list.vue' /* webpackChunkName: "pages/touch/courses/recorded-detail/syllabus-list" */))
const _4708b904 = () => interopDefault(import('../../../src/client/pages/touch/order/shopping-cart/create/index.vue' /* webpackChunkName: "pages/touch/order/shopping-cart/create/index" */))
const _1f47cdc2 = () => interopDefault(import('../../../src/client/pages/touch/activity/group/detail/_id.vue' /* webpackChunkName: "pages/touch/activity/group/detail/_id" */))
const _4d4c054e = () => interopDefault(import('../../../src/client/pages/touch/courses/detail/promote/_packageId.vue' /* webpackChunkName: "pages/touch/courses/detail/promote/_packageId" */))
const _413e0d5e = () => interopDefault(import('../../../src/client/pages/touch/my-courses/recorded-courses/detail/_skuId.vue' /* webpackChunkName: "pages/touch/my-courses/recorded-courses/detail/_skuId" */))
const _4c52d163 = () => interopDefault(import('../../../src/client/pages/touch/my-courses/recorded-courses/video/_skuId.vue' /* webpackChunkName: "pages/touch/my-courses/recorded-courses/video/_skuId" */))
const _35a21c70 = () => interopDefault(import('../../../src/client/pages/touch/order/detail/sub/_id.vue' /* webpackChunkName: "pages/touch/order/detail/sub/_id" */))
const _6074c95a = () => interopDefault(import('../../../src/client/pages/touch/app/teacher/_teacherId.vue' /* webpackChunkName: "pages/touch/app/teacher/_teacherId" */))
const _e2906648 = () => interopDefault(import('../../../src/client/pages/touch/courses/aggregational-detail/_pageId.vue' /* webpackChunkName: "pages/touch/courses/aggregational-detail/_pageId" */))
const _626243a7 = () => interopDefault(import('../../../src/client/pages/touch/courses/available-classes/_courseId.vue' /* webpackChunkName: "pages/touch/courses/available-classes/_courseId" */))
const _641bb211 = () => interopDefault(import('../../../src/client/pages/touch/courses/detail/_coursesId.vue' /* webpackChunkName: "pages/touch/courses/detail/_coursesId" */))
const _335256ab = () => interopDefault(import('../../../src/client/pages/touch/courses/recorded-detail/_skuId.vue' /* webpackChunkName: "pages/touch/courses/recorded-detail/_skuId" */))
const _0332b6dc = () => interopDefault(import('../../../src/client/pages/touch/courses/teacher/_teacherId.vue' /* webpackChunkName: "pages/touch/courses/teacher/_teacherId" */))
const _2d0d1358 = () => interopDefault(import('../../../src/client/pages/touch/goods/detail/_skuId.vue' /* webpackChunkName: "pages/touch/goods/detail/_skuId" */))
const _14902efc = () => interopDefault(import('../../../src/client/pages/touch/my-account/edit-student/_id.vue' /* webpackChunkName: "pages/touch/my-account/edit-student/_id" */))
const _52adf6ea = () => interopDefault(import('../../../src/client/pages/touch/my-class-transfer/class-transfer/_classId.vue' /* webpackChunkName: "pages/touch/my-class-transfer/class-transfer/_classId" */))
const _36a27e32 = () => interopDefault(import('../../../src/client/pages/touch/my-class-transfer/lesson-reschedule/_id.vue' /* webpackChunkName: "pages/touch/my-class-transfer/lesson-reschedule/_id" */))
const _431b60fb = () => interopDefault(import('../../../src/client/pages/touch/my-coupons/detail/_couponId.vue' /* webpackChunkName: "pages/touch/my-coupons/detail/_couponId" */))
const _47aa49b4 = () => interopDefault(import('../../../src/client/pages/touch/my-courses/detail/_classId.vue' /* webpackChunkName: "pages/touch/my-courses/detail/_classId" */))
const _03b89ff4 = () => interopDefault(import('../../../src/client/pages/touch/my-courses/prepare/_id.vue' /* webpackChunkName: "pages/touch/my-courses/prepare/_id" */))
const _c31593a6 = () => interopDefault(import('../../../src/client/pages/touch/my-subscription/cancelSubscription/_id.vue' /* webpackChunkName: "pages/touch/my-subscription/cancelSubscription/_id" */))
const _7c84d772 = () => interopDefault(import('../../../src/client/pages/touch/my-subscription/detail/_id.vue' /* webpackChunkName: "pages/touch/my-subscription/detail/_id" */))
const _1316d2f4 = () => interopDefault(import('../../../src/client/pages/touch/my-unpaid-courses/autopay-notice/_status.vue' /* webpackChunkName: "pages/touch/my-unpaid-courses/autopay-notice/_status" */))
const _f3a71ac0 = () => interopDefault(import('../../../src/client/pages/touch/my-unpaid-courses/class-transfer/_skuId.vue' /* webpackChunkName: "pages/touch/my-unpaid-courses/class-transfer/_skuId" */))
const _47a546c1 = () => interopDefault(import('../../../src/client/pages/touch/order/create/_skuId.vue' /* webpackChunkName: "pages/touch/order/create/_skuId" */))
const _94c6bdd2 = () => interopDefault(import('../../../src/client/pages/touch/order/detail/_id.vue' /* webpackChunkName: "pages/touch/order/detail/_id" */))
const _3aff2a61 = () => interopDefault(import('../../../src/client/pages/touch/order/logistics/_orderId.vue' /* webpackChunkName: "pages/touch/order/logistics/_orderId" */))
const _6930513e = () => interopDefault(import('../../../src/client/pages/touch/order/refund/_id.vue' /* webpackChunkName: "pages/touch/order/refund/_id" */))
const _6ae61e71 = () => interopDefault(import('../../../src/client/pages/touch/order/result/_orderId.vue' /* webpackChunkName: "pages/touch/order/result/_orderId" */))
const _e6cc268e = () => interopDefault(import('../../../src/client/pages/touch/resources/list/_moduleId.vue' /* webpackChunkName: "pages/touch/resources/list/_moduleId" */))
const _519dacd3 = () => interopDefault(import('../../../src/client/pages/touch/resources/send-mail/_id.vue' /* webpackChunkName: "pages/touch/resources/send-mail/_id" */))
const _425931b4 = () => interopDefault(import('../../../src/client/pages/touch/resources/view/_id.vue' /* webpackChunkName: "pages/touch/resources/view/_id" */))
const _0917d46f = () => interopDefault(import('../../../src/client/pages/touch/settings/myaddresses/_id.vue' /* webpackChunkName: "pages/touch/settings/myaddresses/_id" */))
const _71061ee6 = () => interopDefault(import('../../../src/client/pages/touch/settings/security/_id.vue' /* webpackChunkName: "pages/touch/settings/security/_id" */))
const _7bbddf98 = () => interopDefault(import('../../../src/client/pages/touch/teachers/detail/_teacherId.vue' /* webpackChunkName: "pages/touch/teachers/detail/_teacherId" */))
const _2802d5b8 = () => interopDefault(import('../../../src/client/pages/touch/test/exam-recommended-classes/_sessionId.vue' /* webpackChunkName: "pages/touch/test/exam-recommended-classes/_sessionId" */))
const _0b6e641d = () => interopDefault(import('../../../src/client/pages/touch/test/recommended-classes/_redeem.vue' /* webpackChunkName: "pages/touch/test/recommended-classes/_redeem" */))
const _12e5fecc = () => interopDefault(import('../../../src/client/pages/touch/module-preview/_id.vue' /* webpackChunkName: "pages/touch/module-preview/_id" */))
const _102d81f0 = () => interopDefault(import('../../../src/client/pages/touch/settings/_id.vue' /* webpackChunkName: "pages/touch/settings/_id" */))
const _7d3a89d6 = () => interopDefault(import('../../../src/client/pages/touch/_.vue' /* webpackChunkName: "pages/touch/_" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/1-to-1-assessment",
    component: _2894318d,
    name: "1-to-1-assessment"
  }, {
    path: "/about-think-academy",
    component: _09c27a4a,
    name: "about-think-academy"
  }, {
    path: "/about-xes",
    component: _022efb9f,
    name: "about-xes"
  }, {
    path: "/aboutus",
    component: _0cb9d2e4,
    name: "aboutus"
  }, {
    path: "/acceptable-use-policy",
    component: _3a080dfc,
    name: "acceptable-use-policy"
  }, {
    path: "/activated-page",
    component: _64f12a08,
    name: "activated-page"
  }, {
    path: "/appointmentDiagnosis",
    component: _70a22e62,
    name: "appointmentDiagnosis"
  }, {
    path: "/assessment",
    component: _6a2dd3ff,
    name: "assessment"
  }, {
    path: "/bind-email",
    component: _02182ac9,
    name: "bind-email"
  }, {
    path: "/career",
    component: _47338abb,
    name: "career"
  }, {
    path: "/child-privacy",
    component: _19d56b60,
    name: "child-privacy"
  }, {
    path: "/cookies-policy",
    component: _f297ae26,
    name: "cookies-policy"
  }, {
    path: "/course-material-fee-policy",
    component: _64d7b6ec,
    name: "course-material-fee-policy"
  }, {
    path: "/courses",
    component: _2e639771,
    name: "courses"
  }, {
    path: "/customer-service",
    component: _2f341283,
    name: "customer-service"
  }, {
    path: "/download",
    component: _aa9bb276,
    name: "download"
  }, {
    path: "/faq",
    component: _104ee04f,
    name: "faq"
  }, {
    path: "/home",
    component: _dd481948,
    name: "home"
  }, {
    path: "/internet-safety-tips",
    component: _ec7cfd78,
    name: "internet-safety-tips"
  }, {
    path: "/level-test",
    component: _0312f738,
    name: "level-test"
  }, {
    path: "/locations",
    component: _3f2cc417,
    name: "locations"
  }, {
    path: "/login",
    component: _a6036d3c,
    name: "login"
  }, {
    path: "/my-account",
    component: _0a4e57c9,
    name: "my-account"
  }, {
    path: "/my-class-transfer",
    component: _a24a1c4c,
    name: "my-class-transfer"
  }, {
    path: "/pdf-viewer",
    component: _8efffa6c,
    name: "pdf-viewer"
  }, {
    path: "/pricing-policy",
    component: _accbfed4,
    name: "pricing-policy"
  }, {
    path: "/privacy-notice",
    component: _01c1d33a,
    name: "privacy-notice"
  }, {
    path: "/privacy-policy",
    component: _57d53914,
    name: "privacy-policy"
  }, {
    path: "/refund-policy",
    component: _4f69c0c0,
    name: "refund-policy"
  }, {
    path: "/resources",
    component: _c956c3c4,
    name: "resources"
  }, {
    path: "/safeguarding-policy",
    component: _6d7f3d2e,
    name: "safeguarding-policy"
  }, {
    path: "/shopping-cart",
    component: _c820c544,
    name: "shopping-cart"
  }, {
    path: "/short-term-course",
    component: _5c1f7b44,
    name: "short-term-course"
  }, {
    path: "/student-portal",
    component: _54d74d0a,
    name: "student-portal"
  }, {
    path: "/subscription-manage",
    component: _3189eae4,
    name: "subscription-manage"
  }, {
    path: "/success-page",
    component: _05f4ca16,
    name: "success-page"
  }, {
    path: "/teacher",
    component: _b760d9ca,
    name: "teacher"
  }, {
    path: "/teachers",
    component: _1c57f064,
    name: "teachers"
  }, {
    path: "/terms-and-conditions",
    component: _638b949e,
    name: "terms-and-conditions"
  }, {
    path: "/terms-of-use",
    component: _6cade614,
    name: "terms-of-use"
  }, {
    path: "/uk-tax-strategy",
    component: _302f85e5,
    name: "uk-tax-strategy"
  }, {
    path: "/user-agreement",
    component: _35e7c976,
    name: "user-agreement"
  }, {
    path: "/app/brand-introduction",
    component: _d99a8aea,
    name: "app-brand-introduction"
  }, {
    path: "/app/debug",
    component: _6a94829b,
    name: "app-debug"
  }, {
    path: "/app/faq",
    component: _2b1591e1,
    name: "app-faq"
  }, {
    path: "/app/privacy-policy",
    component: _1bf63842,
    name: "app-privacy-policy"
  }, {
    path: "/app/terms-of-use",
    component: _29c0d8c2,
    name: "app-terms-of-use"
  }, {
    path: "/careers/job-listings",
    component: _7e889396,
    name: "careers-job-listings"
  }, {
    path: "/courses/list",
    component: _95bafcdc,
    name: "courses-list"
  }, {
    path: "/customer-service/problem",
    component: _1d7bf613,
    name: "customer-service-problem"
  }, {
    path: "/home/config",
    component: _dcc9af32,
    name: "home-config"
  }, {
    path: "/my-account/add-student",
    component: _deca276e,
    name: "my-account-add-student"
  }, {
    path: "/my-class-transfer/order-acknowledgement",
    component: _e06ccb74,
    name: "my-class-transfer-order-acknowledgement"
  }, {
    path: "/my-coupons/history",
    component: _8f6b9824,
    name: "my-coupons-history"
  }, {
    path: "/my-coupons/list",
    component: _325f795a,
    name: "my-coupons-list"
  }, {
    path: "/my-courses/list",
    component: _0a02f74f,
    name: "my-courses-list"
  }, {
    path: "/my-subscription/list",
    component: _0dbe52ec,
    name: "my-subscription-list"
  }, {
    path: "/my-unpaid-courses/list",
    component: _67e3f6ce,
    name: "my-unpaid-courses-list"
  }, {
    path: "/my-wish/list",
    component: _5d77c722,
    name: "my-wish-list"
  }, {
    path: "/order/list",
    component: _019d5fbc,
    name: "order-list"
  }, {
    path: "/shopping-cart/OfferDetails",
    component: _572682ec,
    name: "shopping-cart-OfferDetails"
  }, {
    path: "/student-portal/student-edit",
    component: _c99129f4,
    name: "student-portal-student-edit"
  }, {
    path: "/test/recommended-courses",
    component: _d1d4cb74,
    name: "test-recommended-courses"
  }, {
    path: "/test/test-results",
    component: _6ac72b5e,
    name: "test-test-results"
  }, {
    path: "/courses/recorded-detail/syllabus-list",
    component: _312e4b64,
    name: "courses-recorded-detail-syllabus-list"
  }, {
    path: "/order/shopping-cart/create",
    component: _4708b904,
    name: "order-shopping-cart-create"
  }, {
    path: "/activity/group/detail/:id?",
    component: _1f47cdc2,
    name: "activity-group-detail-id"
  }, {
    path: "/courses/detail/promote/:packageId?",
    component: _4d4c054e,
    name: "courses-detail-promote-packageId"
  }, {
    path: "/my-courses/recorded-courses/detail/:skuId?",
    component: _413e0d5e,
    name: "my-courses-recorded-courses-detail-skuId"
  }, {
    path: "/my-courses/recorded-courses/video/:skuId?",
    component: _4c52d163,
    name: "my-courses-recorded-courses-video-skuId"
  }, {
    path: "/order/detail/sub/:id?",
    component: _35a21c70,
    name: "order-detail-sub-id"
  }, {
    path: "/app/teacher/:teacherId?",
    component: _6074c95a,
    name: "app-teacher-teacherId"
  }, {
    path: "/courses/aggregational-detail/:pageId?",
    component: _e2906648,
    name: "courses-aggregational-detail-pageId"
  }, {
    path: "/courses/available-classes/:courseId?",
    component: _626243a7,
    name: "courses-available-classes-courseId"
  }, {
    path: "/courses/detail/:coursesId?",
    component: _641bb211,
    name: "courses-detail-coursesId"
  }, {
    path: "/courses/recorded-detail/:skuId?",
    component: _335256ab,
    name: "courses-recorded-detail-skuId"
  }, {
    path: "/courses/teacher/:teacherId?",
    component: _0332b6dc,
    name: "courses-teacher-teacherId"
  }, {
    path: "/goods/detail/:skuId?",
    component: _2d0d1358,
    name: "goods-detail-skuId"
  }, {
    path: "/my-account/edit-student/:id",
    component: _14902efc,
    name: "my-account-edit-student-id"
  }, {
    path: "/my-class-transfer/class-transfer/:classId?",
    component: _52adf6ea,
    name: "my-class-transfer-class-transfer-classId"
  }, {
    path: "/my-class-transfer/lesson-reschedule/:id?",
    component: _36a27e32,
    name: "my-class-transfer-lesson-reschedule-id"
  }, {
    path: "/my-coupons/detail/:couponId?",
    component: _431b60fb,
    name: "my-coupons-detail-couponId"
  }, {
    path: "/my-courses/detail/:classId?",
    component: _47aa49b4,
    name: "my-courses-detail-classId"
  }, {
    path: "/my-courses/prepare/:id?",
    component: _03b89ff4,
    name: "my-courses-prepare-id"
  }, {
    path: "/my-subscription/cancelSubscription/:id?",
    component: _c31593a6,
    name: "my-subscription-cancelSubscription-id"
  }, {
    path: "/my-subscription/detail/:id?",
    component: _7c84d772,
    name: "my-subscription-detail-id"
  }, {
    path: "/my-unpaid-courses/autopay-notice/:status?",
    component: _1316d2f4,
    name: "my-unpaid-courses-autopay-notice-status"
  }, {
    path: "/my-unpaid-courses/class-transfer/:skuId?",
    component: _f3a71ac0,
    name: "my-unpaid-courses-class-transfer-skuId"
  }, {
    path: "/order/create/:skuId?",
    component: _47a546c1,
    name: "order-create-skuId"
  }, {
    path: "/order/detail/:id?",
    component: _94c6bdd2,
    name: "order-detail-id"
  }, {
    path: "/order/logistics/:orderId?",
    component: _3aff2a61,
    name: "order-logistics-orderId"
  }, {
    path: "/order/refund/:id?",
    component: _6930513e,
    name: "order-refund-id"
  }, {
    path: "/order/result/:orderId?",
    component: _6ae61e71,
    name: "order-result-orderId"
  }, {
    path: "/resources/list/:moduleId?",
    component: _e6cc268e,
    name: "resources-list-moduleId"
  }, {
    path: "/resources/send-mail/:id?",
    component: _519dacd3,
    name: "resources-send-mail-id"
  }, {
    path: "/resources/view/:id?",
    component: _425931b4,
    name: "resources-view-id"
  }, {
    path: "/settings/myaddresses/:id?",
    component: _0917d46f,
    name: "settings-myaddresses-id"
  }, {
    path: "/settings/security/:id?",
    component: _71061ee6,
    name: "settings-security-id"
  }, {
    path: "/teachers/detail/:teacherId?",
    component: _7bbddf98,
    name: "teachers-detail-teacherId"
  }, {
    path: "/test/exam-recommended-classes/:sessionId?",
    component: _2802d5b8,
    name: "test-exam-recommended-classes-sessionId"
  }, {
    path: "/test/recommended-classes/:redeem?",
    component: _0b6e641d,
    name: "test-recommended-classes-redeem"
  }, {
    path: "/module-preview/:id?",
    component: _12e5fecc,
    name: "module-preview-id"
  }, {
    path: "/settings/:id?",
    component: _102d81f0,
    name: "settings-id"
  }, {
    path: "/*",
    component: _7d3a89d6,
    name: "all"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
