<template>
  <van-popup
    v-model="showPopup"
    :overlay="overlay"
    overlay-class="popup-overlay"
  >
    <div v-if="qrcodeWechatUrl" class="popup-wechat is-fr">
      <div class="qrcode">
        <img v-lazy-load :data-src="qrcodeUrl" alt="">
        <div v-if="description" class="description">
          {{ description }}
        </div>
      </div>
      <div class="line" />
      <div class="qrcode">
        <img v-lazy-load :data-src="qrcodeWechatUrl" alt="">
        <div v-if="descriptionWechat" class="description">
          {{ descriptionWechat }}
        </div>
      </div>
    </div>
    <div v-else class="popup-wechat">
      <div class="qrcode">
        <img v-lazy-load :data-src="qrcodeUrl" alt="">
      </div>
      <div v-if="description" class="description">
        {{ description }}
      </div>
    </div>
  </van-popup>
</template>

<script>
import bus from 'common/bus';

export default {
  data() {
    return {
      showPopup: false,
      overlay: true,
      qrcodeUrl: '',
      qrcodeWechatUrl: '',
      description: '',
      descriptionWechat: '',
    }
  },
  mounted() {
    bus.$on('touch.common.WechatPopup.show', (data) => {
      if (!data.qrcodeUrl) {
        return
      }
      this.qrcodeUrl = data.qrcodeUrl
      this.qrcodeWechatUrl = data.qrcodeWechatUrl
      this.description = data.description
      this.descriptionWechat = data.descriptionWechat
      this.showPopup = true;
    });
  },
  methods: {

  },
}
</script>

<style lang="scss" scoped>
.popup-wechat {
  padding: px2vw(50);

  .qrcode {
    background: #f1f1f1;
  }

  .qrcode,
  .qrcode img {
    width: px2vw(380);
    height: px2vw(380);
  }

  .description {
    margin-top: px2vw(24);
    font-size: px2vw(24);
    line-height: px2vw(40);
    text-align: center;
    color: #666;
  }
}

.is-fr {
  width: 330px;
  height: 182px;
  padding: 14px 22px 0;
  display: flex;

  .qrcode,
  .qrcode img {
    width: 138px;
    height: 138px;
  }

  .line {
    margin-top: 18px;
    width: 1px;
    height: 100px;
    border: 1px solid #f1f1f1;
  }

  .description {
    margin-top: 0;
    width: 146px;
  }
}
</style>
