<template>
  <van-field ref="loginFormCaptchInput" v-model="inputValue" class="email-input font-medium" name="email"
             :placeholder="$t('login.login.loginForm.email.placeholder')" autocomplete="off" :rules="rules" @blur="emailBlurHandler"
  />
</template>
<script>
import { filterBlank } from 'common/utils.js';

export default {
  props: {
    value: {
      type: [String, Number],
      default: '',
    },
    rules: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      inputValue: '',
    }
  },
  watch: {
    value(val) {
      this.inputValue = val
    },
    inputValue(val) {
      this.$emit('input', val)
    },
  },
  methods: {
    emailBlurHandler() {
      this.value = filterBlank(this.value);
    },
  },
}
</script>
<style lang="scss" scoped>
  .email-input {
    margin: 0;
  }
</style>
