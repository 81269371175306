const description = ` <p>EdTech 驅動的實時在線學習平台</p>
<ul>
  <li>訪問互動式虛擬教室</li>

  <li>回答問題並享受學習活動</li>

  <li>有趣地贏取更多的獎勵</li>
</ul>
`;
export default {
  title: 'Think Academy 課堂',
  requirement: {
    ios: '只支持10.0及以上版本 <br/> 為了更好的課堂體驗不支持使用Phone5/5c/5s、iPhone6/Plus、iPhone6s/Plus和iPhone SE',
    android: '只支持Android 6.0及以上<br/>最低配置（平板電腦）<br/>2.0 GHz處理器速度，4GB RAM',
  },
  description: `${description}`,
  tip: '對於桌上型電腦用戶，請從筆記型電腦上的Think Academy網站下載該軟件。',
  download: '下載',
  guideNotice: '請在瀏覽器內打開此網頁鏈接<br>下載我們的APP',
}
