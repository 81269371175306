<template>
  <div v-if="show" class="loading-wrapper" :class="className">
    <div class="loading-contenter" :style="{ marginTop: marginTop + 'vw', marginBottom: marginBottom + 'vw' }">
      <div class="loading-logo" role="img">
        <img v-lazy-load :data-src="$t('common.logo.loading')" alt="loading">
      </div>
      <div class="loading-animation" />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    // 显示状态
    show: {
      default: false,
      type: Boolean,

    },
    // 尺寸
    size: {
      default: 'default',
      type: String,
      validator(value) {
        return ['small', 'default'].indexOf(value) !== -1;
      },
    },
    // 上间距
    marginTop: {
      default: 30,
      type: Number,
    },
    // 下间距
    marginBottom: {
      default: 30,
      type: Number,
    },
  },
  data() {
    return {

    }
  },
  computed: {
    className() {
      return `loading-${this.size}`
    },
  },
}
</script>

<style lang="scss">
// 默认尺寸
.loading-default {
  .loading-contenter {
    width: px2vw(90);
  }

  .loading-logo,
  .loading-logo img {
    width: px2vw(45);
    height: px2vw(45);
    position: relative;
  }

  .loading-animation,
  .loading-animation::after {
    border-radius: 50%;
    width: px2vw(90);
    height: px2vw(90);
  }
}
// 小尺寸
.loading-small {
  .loading-contenter {
    width: px2vw(90);
  }

  .loading-logo,
  .loading-logo img {
    width: px2vw(45);
    height: px2vw(45);
  }

  .loading-animation,
  .loading-animation::after {
    border-radius: 50%;
    width: px2vw(90);
    height: px2vw(90);
  }
}

// loading样式
.loading-wrapper {
  overflow: hidden;

  .loading-contenter {
    position: relative;
    margin-left: auto;
    margin-right: auto;
  }

  .loading-logo {
    position: absolute;
    left: px2vw(22.5);
    top: px2vw(22.5);
  }

  .loading-animation {
    font-size: px2vw(10);
    position: relative;
    text-indent: -9999em;
    border-top: px2vw(3) solid var(--txt-color-link3);
    border-right: px2vw(3) solid var(--txt-color-link3);
    border-bottom: px2vw(3) solid var(--txt-color-link3);
    border-left: px2vw(3) solid var(--txt-color-link);
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-animation: load 1.1s infinite linear;
    animation: load 1.1s infinite linear;
  }
}

@-webkit-keyframes load {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes load {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
</style>
