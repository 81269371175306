<template>
  <div>
    <van-form validate-first class="student-info-form" @submit="handleSubmit">
      <div class="display-name-wrapper">
        <van-field
          v-model="stuFormData.nickName"
          clearable
          autocomplete="off"
          name="nickName"
          :label="pageConfig.displayName"
          class="user-name normal-pure-white-field"
          maxlength="65"
          :placeholder="pageConfig.enter + pageConfig.displayName"
          :class="focusFieldName=='nickName'?'active-van-cell':''"
          :rules="rules.nickName"
          @focus="focusFieldName='nickName'"
          @blur="focusFieldName=''"
        />
        <p class="display-name-tip font-medium">
          {{ pageConfig.nameTip }}, {{ pageConfig.maximun10 }}
        </p>
      </div>

      <div class="student-info-wrapper">
        <van-field
          v-if="locale === 'hk'"
          v-model="stuFormData.lastName"
          clearable
          autocomplete="off"
          name="lastName"
          :label="pageConfig.lastName"
          class="last-name normal-pure-white-field"
          maxlength="32"
          :class="focusFieldName=='lastName'?'active-van-cell':''"
          :placeholder="pageConfig.enter + (pageConfig.language || '') + pageConfig.lastName"
          :rules="rules.lastName"
          @focus="focusFieldName='lastName'"
          @blur="focusFieldName=''"
        />
        <van-field
          v-model="stuFormData.firstName"
          clearable
          autocomplete="off"
          name="firstName"
          :label="pageConfig.firstName"
          class="first-name normal-pure-white-field"
          :class="focusFieldName=='firstName'?'active-van-cell':''"
          maxlength="32"
          :placeholder="`${pageConfig.enter}${pageConfig.language || ''}${ pageConfig.firstName}`"
          :rules="rules.firstName"
          @focus="focusFieldName='firstName'"
          @blur="focusFieldName=''"
        />
        <van-field
          v-if="locale !== 'hk'"
          v-model="stuFormData.lastName"
          clearable
          autocomplete="off"
          name="lastName"
          :label="pageConfig.lastName"
          class="last-name normal-pure-white-field"
          maxlength="32"
          :class="focusFieldName=='lastName'?'active-van-cell':''"
          :placeholder="pageConfig.enter + pageConfig.lastName"
          :rules="rules.lastName"
          @focus="focusFieldName='lastName'"
          @blur="focusFieldName=''"
        />
        <van-field
          readonly
          clickable
          name="gradeName"
          class="grade-name normal-pure-white-field"
          :value="stuFormData.gradeName"
          :label="$t('page.common.grade')"
          :right-icon="isShowSelectGradePopup?'arrow-up':'arrow-down'"
          :placeholder="pageConfig.pleaseSelect"
          :rules="rules.gradeName"
          @click="openSelectGradePopup"
        />
      </div>

      <!-- <div class="email-wrapper">
        <van-field
          v-model="stuFormData.email"
          clearable
          type="email"
          class="email normal-pure-white-field"
          autocomplete="off"
          name="email"
          :label="pageConfig.email"
          :placeholder="pageConfig.enter + pageConfig.email"
          :rules="rules.email"
          @change="emailChangeHandler"
        />
      </div> -->
      <div v-if="tipTextTopOfBtn" class="tip-text font-medium">
        {{ tipTextTopOfBtn }}
      </div>
      <div class="submit-btn">
        <van-button round block class="button button-normal" native-type="submit" :loading="isSubmiting" :disabled="isSubmiting">
          {{ submitBtnText }}
        </van-button>
      </div>
    </van-form>

    <!-- 年级选择器 -->
    <GradePopup ref="studentFormGradeSelectPopup" :default-index="selectedIndex" :grade-list="gradeList" @grade-change="gradeChangeHandler" />
  </div>
</template>

<script>
import { oneApi } from 'config/api';
import GradePopup from 'components/common/GradePopup/index.vue';

const initStu = {
  nickName: '',
  firstName: '',
  lastName: '',
  gradeId: '',
  gradeName: '',
  email: '',
}

export default {
  components: { GradePopup },
  props: {
    studentData: {
      default: () => (initStu),
      type: Object,
    },
    tipTextTopOfBtn: {
      default: '',
      type: String,
    },
    submitBtnText: {
      default: 'Save',
      type: String,
    },
  },
  data() {
    const pageConfig = this.$t('page.personalInfo')
    return {
      pageConfig,
      isSubmiting: false,
      isShowSelectGradePopup: false,
      selectedIndex: 0,
      gradeList: [],
      emailChanged: false,
      stuFormData: this.studentData,
      rules: {
        nickName: [
          { pattern: pageConfig.pattern.nickname, message: pageConfig.maximun },
          { required: true, message: pageConfig.invalid },
        ],
        firstName: [
          { pattern: pageConfig.pattern.name, message: pageConfig.maximun32 },
          { required: true, message: pageConfig.invalid },
        ],
        lastName: [
          { pattern: pageConfig.pattern.name, message: pageConfig.maximun32 },
          { required: true, message: pageConfig.invalid },
        ],
        gradeName: [{ required: true, message: pageConfig.invalid }],
        email: [
          { required: true, message: pageConfig.invalid },
        ],
      },
      focusFieldName: '',
      locale: this.$store.state.locale,
    }
  },
  watch: {
    emailChanged(newVal) {
      if (newVal) {
        this.rules.email.push({ pattern: /^([A-Za-z0-9_\-.])+@([A-Za-z0-9_\-.])+\.([A-Za-z]{2,8})$/, message: this.pageConfig.invalid })
      }
    },
  },
  mounted() {
    this.getGradeList();
  },
  methods: {
    handleSubmit() {
      this.isSubmiting = true;
      this.$emit('save', this.stuFormData)
      this.isSubmiting = false;
    },
    gradeChangeHandler(index) {
      if (index < 0 || index === undefined) return;
      this.selectedIndex = index;
      this.stuFormData.gradeId = this.gradeList[index].value
      this.stuFormData.gradeName = this.gradeList[index].name
    },
    async getGradeList() {
      const res = await this.$axios.post(oneApi.queryGradesAll, {}, { rewritePostBody: true })

      if (Number(res.code) !== 0) {
        this.$Toast(res.msg);
        return;
      }

      const resData = res.data || {};

      const gradeList = resData.list;
      let gradeMap = []
      gradeList.forEach((item) => {
        gradeMap = gradeMap.concat(item.list)
      });

      this.gradeList = gradeMap;
    },
    openSelectGradePopup() {
      this.$refs.studentFormGradeSelectPopup.open();
    },
    emailChangeHandler() {
      this.emailChanged = true
    },
  },
}
</script>

<style lang="scss" scoped>
.student-info-form {
  position: relative;
  margin: 0 auto;
  padding-bottom: px2vw(144);
  width: px2vw(686);

  .normal-pure-white-field.user-name {
    &::after {
      border-bottom: none;
    }
  }

  .display-name-wrapper,
  .email-wrapper {
    border-radius: px2vw(20);
    background: #fff;
    overflow: hidden;
  }

  .display-name-wrapper {
    .display-name-tip {
      font-size: px2vw(24);
      padding: 0 px2vw(32)  px2vw(32);
      line-height: px2vw(28);
      color: var(--txt-color-lv2);
      word-break: break-word;
    }
  }

  .student-info-wrapper {
    margin: px2vw(38) 0;
    border-radius: px2vw(20);
    background: #fff;
    overflow: hidden;
  }

  .tip-text {
    margin-top: px2vw(20);
    font-size: px2vw(24);
    line-height: px2vw(28);
    color: var(--txt-color-lv2);
  }

  .submit-btn {
    position: absolute;
    bottom: px2vw(20);
    width: px2vw(686);
  }
}
</style>
